<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/settings')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("настройки_кассы") }}
      v-spacer
      v-btn(v-if="configurationChanged" color="primary" :disabled="loading" @click="showSaveChangesPinpad()") {{ $t("сохранить") }}

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row(v-if="!readonly")
        v-col
          v-list.re-v-list-fulltext
            v-subheader {{ $t("настройки") }}
            v-list-item(@click="openNameDialog()")
              v-list-item-content
                v-list-item-title {{ $t("название") }}
              v-list-item-content.re-text-align-end
                v-list-item-subtitle.subtitle-1 {{ name }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("плательщик_ндс") }}
              v-list-item-action(@click="isShiftClosed()")
                v-switch(color="teal" :input-value="taxMode" @change="taxModeChanged" :disabled="this.cashRegister.shiftOpen")
            v-list-item(@click="openTaxationTypeDialog()")
              v-list-item-content
                v-list-item-title {{ $t("режим_налогообложения") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ $t("taxationType." + taxationType) }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("автоматическое_изъятие_наличных") }}
              v-list-item-action(@click="isShiftClosed()")
                v-switch(color="teal" :input-value="withdrawMoney" @change="withdrawMoneyChanged" :disabled="this.cashRegister.shiftOpen")
            v-list-item(@click="openСloseShiftScheduleDialog()")
              v-list-item-content
                v-list-item-title {{ $t("автоматическое_закрытие_смены") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ closeShiftSchedule ? closeShiftSchedule : '--:--' }}
            v-list-item(@click="openСloseShiftScheduleEmailDialog()")
              v-list-item-content
                v-list-item-title {{ $t("отправка_z_отчёта_на_электронную_почту") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ closeShiftEmail ? closeShiftEmail : '---' }}
            v-list-item(to="/kkm/pincode")
              v-list-item-content
                v-list-item-title {{ $t("пин-код") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 ****
            v-list-item(to="/kkm/token" v-if="cashRegister.fdo !== 'REK'")
              v-list-item-content
                v-list-item-title {{ $t("токен") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 *******

      v-row
        v-col
          v-list.re-v-list-fulltext
            v-subheader {{ $t("информация") }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("касса") }}
              v-list-item-action
                v-chip.rounde.text-uppercase(small :color="$t(`cashRegister.color.${cashRegisterStatus}`)") {{ $t(`cashRegister.status.${cashRegisterStatus}`) }}
                  span(v-if="cashRegister.fdoMode === 'OFFLINE' && hoursLeftTillBlocked >= 0") &nbsp; ({{ hoursLeftTillBlocked }} {{ $t("час") }} )
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("знм") }}
                v-list-item-subtitle {{ $t("заводской_номер") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1
                  v-btn.mr-1(icon @click="copySerialNumber(cashRegister.serialNumber)")
                    v-icon mdi-content-copy
                  | {{ cashRegister.serialNumber }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("инм_id") }}
                v-list-item-subtitle {{ $t("номер_кассы_в_офд") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ cashRegister.fdoId }}
            v-list-item
              v-list-item-content.overflow-visible
                v-list-item-title {{ $t("регистрационный_номер") }}
                v-btn.px-3.mt-1.rounded-lg(v-if="regCardUrl" color="primary" height="25" @click="downloadRegistrationCard()") {{ $t("регистрационная_карта") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ cashRegister.registrationNumber }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("офд") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ $t(`fdo.${cashRegister.fdo}.title`) }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("протокол") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 2.0.2
            v-list-item(v-if="preferences && preferences.ofd && preferences.ofd.accountId")
              v-list-item-content
                v-list-item-title {{ $t("лицевой_счёт_офд") }}
                v-list-item-subtitle
                  a(@click.prevent="howToPay()") {{ $t("как_оплатить_услугу_офд") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1
                  v-btn.mr-1(icon @click="copyOfdAccountId(preferences.ofd.accountId)")
                    v-icon mdi-content-copy
                  | {{ preferences.ofd.accountId }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("организация") }}
                v-list-item-subtitle
                  a(v-if="cashRegister.fdo !== 'REK'" @click.prevent="$openLink('https://link.rekassa.kz/name-change', '_system')") {{ $t("как_поменять_наименование") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ organization.businessName }}
                v-list-item-subtitle {{ $t("бин_иин") }} {{ organization.businessId }}
            v-list-item
              v-list-item-content.overflow-visible
                v-list-item-title {{ $t("торговая_точка") }}
                  v-list-item-subtitle
                    v-btn.px-3.mt-1.rounded-lg(v-if="isTestMode && cashRegister.fdo === 'REK'" :color="reRegStatus.color" height="25" @click="mode = 'test'; reRegistrate()") {{ reRegStatus.text }}
                    a(v-else @click.prevent="$openLink('https://link.rekassa.kz/address-change', '_system')") {{ $t("как_поменять_адрес") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ cashRegister.pos.title }}
                v-list-item-subtitle {{ cashRegister.pos.address }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("дата_активации") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ cashRegister.registrationDate | moment("DD-MM-YYYY") }}
            v-list-item(v-if="cashRegister.deregistrationDate")
              v-list-item-content
                v-list-item-title {{ $t("дата_деактивации") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ cashRegister.deregistrationDate | moment("DD-MM-YYYY") }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("роль") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ $t('role.' + roles[0]) }}

            v-row
              v-col.py-0(align="center")
                template(v-if="!readonly")
                  v-btn.my-3(v-if="isTestMode && cashRegister.fdo === 'REK'" text :color="deRegStatus.color" @click="mode = 'test'; showDeRegistratePinpad()") {{ deRegStatus.text }}
                  v-btn.my-3(v-else text color="error" :disabled="loading" @click="$router.push('/kkm/deactivation')") {{ $t("деактивировать_кассу") }}
                template(v-if="roles[0] === 'CASHIER'")
                  v-btn.my-3(text color="error" :disabled="loading" @click="deleteMe()") {{ $t("отключиться_от_кассы") }}

  v-dialog(v-model="nameDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("введите_название_кассы") }}
      v-card-text
        v-container
          v-form.mt-3(ref="nameForm" @submit.prevent="saveName()")
            v-text-field(:label="$t('название')" v-model="nameInput" type="text" :rules="[rules.required]" autocomplete="off" maxlength="40" counter="40")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="nameDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveName()") {{ $t("изменить") }}

  v-dialog(v-model="taxationTypeDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("выберите_вид_налогообложения") }}
      v-card-text
        v-container
          v-form.mt-3(ref="taxationTypeForm" @submit.prevent="saveTaxationType()")
            v-select(:label="$t('режим_налогообложения')" v-model="taxationTypeSelect" :items="taxationTypes" item-value="value" item-text="title")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="taxationTypeDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveTaxationType()") {{ $t("изменить") }}

  v-dialog(v-model="closeShiftScheduleDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("время_ежедневного_автоматического_закрытия_смены") }}
      v-card-text.pt-0
        v-container
          v-form(ref="closeShiftScheduleForm" @submit.prevent="saveСloseShiftSchedule()")
            v-row
              v-col
                v-checkbox(v-model="closeShiftScheduleCheckbox" :label="$t('включить')")
              v-col
                v-text-field(:label="$t('время')" placeholder="22:30" v-model="closeShiftScheduleSelect" v-mask="timeMask" :rules="[closeShiftScheduleCheckbox ? rules.length(5) : false]" validate-on-blur :disabled="!closeShiftScheduleCheckbox" autocomplete="off" :hint="$t('время_фактического_закрытия_смены_может_быть_позднее_на_минут')")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="closeShiftScheduleDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveСloseShiftSchedule()") {{ $t("сохранить") }}

  v-dialog(v-model="closeShiftEmailDialog" persistent max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("отправка_z_отчёта_на_электронную_почту") }}
      v-card-text
        v-container
          v-form(ref="closeShiftEmailForm" @submit.prevent="saveCloseShiftEmail()")
            v-row
              v-col
                v-checkbox(v-model="closeShiftEmailCheckbox" :label="$t('включить')")
              v-col
                v-select(:label="$t('язык_отчёта')" v-model="closeShiftLocaleInput" :items="localeTypes" item-value="value" item-text="title" :disabled="!closeShiftEmailCheckbox")
            v-row
              v-col
                v-text-field(v-model="closeShiftEmailInput" type="email" :rules="[rules.email]" :label="$t('электронная_почта')" placeholder="client@rekassa.kz" prepend-inner-icon="mdi-at" autocomplete="off" clearable validate-on-blur :autofocus="!$isCordova()" :disabled="!closeShiftEmailCheckbox")
                p {{ $t("при_закрытии_смены_z_отчёт_будет_отправлен_на_указанный_вами_адрес_электронной_почты") }}
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="closeShiftEmailDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveCloseShiftEmail()") {{ $t("сохранить") }}

  v-dialog(v-model="paymentMethodsDialog" scrollable max-width="400")
    v-card
      v-card-title.pb-2 {{ $t("выберите_способ_оплаты") }}
      v-container
        v-list.py-0
          template(v-for="(paymentMethod, $index) in paymentMethods")
            v-list-item.py-1(@click.prevent="$openLink(evalLink(paymentMethod.link), '_system'); paymentMethodsDialog = false")
              v-list-item-avatar
                v-avatar.white--text(:color="paymentMethod.color")
                  span(v-dompurify-html="paymentMethod.avatar")
              v-list-item-content {{ paymentMethod.title }}
            v-divider
      v-card-actions.mb-2
        v-spacer
        v-btn(outlined @click.native="paymentMethodsDialog = false") {{ $t("закрыть") }}

  re-pinpad(v-model="saveChangesDialog" :title="$t('сохранение_настроек')" :subtitle="$t('введите_пин-код')" :text="$t('для_сохранения_настроек')" :loading="loading" :errorText.sync="saveChangesErrorMessage" @action="saveChanges")
  re-pinpad(v-model="deregistrationDialog" :title="$t('снятие_кассы_с_учета')" :subtitle="$t('введите_пин-код')" :text="$t('для_снятия_кассы_с_учета')" :loading="loading" :errorText.sync="deregistrationErrorMessage" @action="checkPincode")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import store from '../../store/index'
import Pinpad from '../utils/PinpadDialog.vue'
import i18n, { getLocale } from '../../i18n/index'

export function timeMask(value) {
  const hours = [
    /[0-2]/,
    value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/,
  ]
  const minutes = [/[0-5]/, /[0-9]/]
  return value.length > 2
    ? [...hours, ':', ...minutes]
    : hours
}

export default {
  components: {
    're-pinpad': Pinpad,
  },

  mixins: [dictionaryMixin],

  data: () => ({
    loading: false,
    configurationChanged: false,

    saveChangesDialog: false,
    saveChangesErrorMessage: null,

    deregistrationDialog: false,
    deregistrationErrorMessage: null,

    nameDialog: false,
    name: null,
    nameInput: null,

    taxMode: null,

    taxationTypeDialog: false,
    taxationTypeSelect: null,
    taxationType: null,

    withdrawMoney: null,

    timeMask,
    closeShiftSchedule: null,
    closeShiftScheduleSelect: null,
    closeShiftScheduleDialog: false,
    closeShiftScheduleCheckbox: false,

    closeShiftEmailDialog: false,
    closeShiftEmailCheckbox: false,
    closeShiftEmail: null,
    closeShiftEmailInput: null,
    closeShiftLocale: null,
    closeShiftLocaleInput: null,

    paymentMethodsDialog: false,
    paymentMethodsJson: null,
    paymentMethods: [],
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      roles: state => state.cashRegisters.cashRegister.roles,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
      service: state => state.cashRegisters.cashRegister.cashRegister.data.service,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
    }),

    cashRegisterStatus() {
      const { status, fdoMode } = this.cashRegister
      if (status === 'REGISTERED') {
        return fdoMode
      }
      return status
    },

    hoursLeftTillBlocked() {
      return this.cashRegister && this.cashRegister.offlineExpireTime ? Math.floor(this.$moment.duration(this.$moment(this.cashRegister.offlineExpireTime).diff(new Date())).asHours()) : null
    },

    readonly() {
      return this.cashRegister.status === 'DEREGISTERED' || !this.isOwner || this.user._links.createCashRegister === undefined
    },

    isOwner() {
      return this.roles.find(r => r === 'OWNER') !== undefined
    },

    locale() {
      return getLocale()
    },

    regCardUrl() {
      const regCardUrl = this.service?.auxiliary?.find((aux) => aux.key === 'REG_CARD_URL')
      return regCardUrl ? regCardUrl.value : null
    },

    reRegStatus() {
      const code = this.cashRegister.data.preferences?.ofd?.rereg?.status?.code

      if (code === 'IN_PROGRESS') {
        return {
          color: 'light-blue',
          text: i18n.t('в_процессе'),
        }
      }
      if (code === 'SENT') {
        return {
          color: 'light-blue',
          text: i18n.t('ожидаем_ответа_налоговой'),
        }
      }
      if (code === 'SUCCESS') {
        return {
          color: 'secondary',
          text: i18n.t('адрес_изменен'),
        }
      }
      if (code === 'ERROR') {
        return {
          color: 'error',
          text: i18n.t('произошла_ошибка'),
        }
      }
      return {
        color: 'primary',
        text: i18n.t('изменить_адрес'),
      }
    },

    deRegStatus() {
      const code = this.cashRegister.data.preferences?.ofd?.dereg?.status?.code

      if (code === 'IN_PROGRESS') {
        return {
          color: 'light-blue',
          text: i18n.t('в_процессе'),
        }
      }
      if (code === 'SENT') {
        return {
          color: 'light-blue',
          text: i18n.t('ожидает_ответа_налоговой'),
        }
      }
      if (code === 'ERROR') {
        return {
          color: 'error',
          text: i18n.t('произошла_ошибка'),
        }
      }
      return {
        color: 'error',
        text: i18n.t('снять_кассу_с_учета'),
      }
    },

    isTestMode() {
      return process.env.VUE_APP_TEST === 'true'
    },
  },

  beforeRouteEnter(to, from, next) {
    if (store.state.cashRegisters.cashRegister.cashRegister.status === 'REGISTERED' || store.state.cashRegisters.cashRegister.cashRegister.status === 'DEREGISTERED') {
      next()
    } else {
      next('/')
    }
  },

  created() {
    this.init()

    this.firebaseRemoteConfigGetString('PAYMENT_METHODS').then((result) => {
      try {
        const paymentMethods = JSON.parse(result)
        this.paymentMethods = paymentMethods[this.cashRegister.fdo]
      } catch (error) {
        //
      }
    })
  },

  mounted() {
    if (this.$route.path === '/kkm/fdo/payment-methods') {
      setTimeout(() => {
        this.$vuetify.goTo(document.body.offsetHeight)
        setTimeout(() => {
          this.paymentMethodsDialog = true
        }, 500)
      }, 500)
      this.$router.push('/kkm/settings')
    }
  },

  methods: {
    ...mapActions({
      saveConfiguration: 'cashRegisters/saveConfiguration',
      savePreferences: 'cashRegisters/savePreferences',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      deleteMyRole: 'cashRegisters/deleteMyRole',
      initCashRegisters: 'cashRegisters/init',
      showMiniApp: 'miniapp/show',
      analyticsLogEvent: 'analytics/logEvent',
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      copyToClipboard: 'tools/copyToClipboard',
    }),

    init() {
      this.name = this.configuration.name
      this.taxMode = this.configuration.taxMode
      this.taxationType = this.configuration.taxationType
      this.closeShiftSchedule = this.configuration.closeShiftSchedule
      this.closeShiftEmail = this.configuration.closeShiftEmail
      this.closeShiftLocale = this.configuration.closeShiftLocale
      this.withdrawMoney = this.configuration.withdrawMoney
    },

    evalLink(link) {
      return link.replace('{businessId}', this.organization.businessId).replace('{accountId}', this.preferences.ofd?.accountId)
    },

    showProtocol() {
      this.showConfirm({
        title: this.$t('протокол_2_0_2'),
        text: this.$t('rekassa_полностью_соответствует_протоколу_офд_2_0_2'),
        rejectText: this.$t('подробнее'),
      }).then(() => {}).catch(() => {
        this.$openLink('https://link.rekassa.kz/2-0-2')
      })
    },

    howToPay() {
      this.copyOfdAccountId(this.preferences.ofd.accountId)
      if (this.paymentMethods.length > 0) {
        this.paymentMethodsDialog = true
        this.$openLink(`https://link.rekassa.kz/${this.cashRegister.fdo}-payment`, '_system')
      }
    },

    copySerialNumber(str) {
      this.copyToClipboard(str)
      this.showSnackbar({ message: this.$t('заводской_номер_скопирован', { number: str }) })
    },

    copyOfdAccountId(str) {
      this.copyToClipboard(str)
      this.showSnackbar({ message: this.$t('лицевой_счёт_скопирован', { number: str }) })
    },

    taxModeChanged(val) {
      this.taxMode = val
      this.checkForConfigurationChange()
    },

    openNameDialog() {
      if (this.isShiftClosed()) {
        this.nameInput = this.name
        this.nameDialog = true
      }
    },
    saveName() {
      if (this.$refs.nameForm.validate()) {
        this.name = this.nameInput
        this.nameDialog = false
        this.checkForConfigurationChange()
      }
    },

    openTaxationTypeDialog() {
      if (this.isShiftClosed()) {
        this.taxationTypeSelect = this.taxationType
        this.taxationTypeDialog = true
      }
    },
    saveTaxationType() {
      this.taxationTypeDialog = false
      this.taxationType = this.taxationTypeSelect
      this.checkForConfigurationChange()
    },

    withdrawMoneyChanged(val) {
      this.withdrawMoney = val
      this.checkForConfigurationChange()
    },

    openСloseShiftScheduleDialog() {
      if (this.isShiftClosed()) {
        this.closeShiftScheduleSelect = this.closeShiftSchedule
        this.closeShiftScheduleCheckbox = !!this.closeShiftSchedule
        this.closeShiftScheduleDialog = true
      }
    },
    saveСloseShiftSchedule() {
      if (this.closeShiftScheduleCheckbox && this.$refs.closeShiftScheduleForm.validate()) {
        this.closeShiftScheduleDialog = false
        this.closeShiftSchedule = this.closeShiftScheduleSelect
        this.checkForConfigurationChange()
      } else if (!this.closeShiftScheduleCheckbox) {
        this.closeShiftScheduleDialog = false
        this.closeShiftScheduleSelect = null
        this.closeShiftSchedule = this.closeShiftScheduleSelect
        this.checkForConfigurationChange()
      }
    },

    openСloseShiftScheduleEmailDialog() {
      if (this.isShiftClosed()) {
        this.closeShiftEmailDialog = true
        this.closeShiftEmailCheckbox = !!this.closeShiftEmail
        this.closeShiftEmailInput = this.closeShiftEmail
        this.closeShiftLocaleInput = this.closeShiftLocale || this.locale
      }
    },
    saveCloseShiftEmail() {
      if (this.closeShiftEmailCheckbox && this.$refs.closeShiftEmailForm.validate()) {
        this.closeShiftEmailDialog = false
        this.closeShiftEmail = this.closeShiftEmailInput
        this.closeShiftLocale = this.closeShiftLocaleInput
        this.checkForConfigurationChange()
      } else if (!this.closeShiftEmailCheckbox) {
        this.closeShiftEmailDialog = false
        this.closeShiftEmailInput = null
        this.closeShiftLocaleInput = null
        this.closeShiftEmail = this.closeShiftEmailInput
        this.closeShiftLocale = this.closeShiftLocaleInput
        this.checkForConfigurationChange()
      }
    },

    checkForConfigurationChange() {
      if (this.taxMode !== this.configuration.taxMode
        || this.taxationType !== this.configuration.taxationType
        || this.name !== this.configuration.name
        || this.withdrawMoney !== this.configuration.withdrawMoney
        || this.closeShiftSchedule !== this.configuration.closeShiftSchedule
        || this.closeShiftEmail !== this.configuration.closeShiftEmail
        || this.closeShiftLocale !== this.configuration.closeShiftLocale) {
        this.configurationChanged = true
      } else {
        this.configurationChanged = false
      }
    },

    isShiftClosed() {
      if (this.cashRegister.shiftOpen) {
        this.showConfirm({
          title: this.$t('требуется_закрыть_смену'),
          resolveText: this.$t('закрыть_смену'),
          rejectText: this.$t('отменить'),
        }).then(() => {
          this.$router.push(`/shifts/${this.cashRegister.id}/${this.cashRegister.shiftNumber}/zxreport`)
        }).catch(() => {})
        return false
      }
      return true
    },

    showSaveChangesPinpad() {
      this.saveChangesDialog = true
    },

    getModifiedConfiguration() {
      return {
        ...this.configuration,
        name: this.name,
        taxMode: this.taxMode,
        taxationType: this.taxationType,

        withdrawMoney: this.withdrawMoney,
        closeShiftScheduleWithdrawMoney: this.withdrawMoney,

        closeShiftSchedule: this.closeShiftSchedule,
        closeShiftEmail: this.closeShiftEmail,
        closeShiftLocale: this.closeShiftLocale,
      }
    },

    saveChanges(pincode) {
      this.loading = true
      this.saveConfiguration({
        cashRegister: this.cashRegister,
        configuration: this.getModifiedConfiguration(),
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.init()
          this.saveChangesDialog = false
          this.loading = false
          this.configurationChanged = false
          this.showSnackbar({ message: this.$t('изменения_успешно_сохранены') })
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.saveChangesErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_сохранить_настройки', { error }) })
          this.selectCashRegister(this.cashRegister.id).then(() => {
            this.init()
            this.saveChangesDialog = false
            this.loading = false
          })
        }
      })
    },

    deleteMe() {
      this.showConfirm({
        title: this.$t('вы_уверены_что_хотите_отключиться_от_кассы', { serial: this.cashRegister.serialNumber, name: this.cashRegister.name, company: `${this.organization.businessName} - ${this.organization.businessId}` }),
        text: this.$t('отключение_приведёт_к_потере_доступа_к_кассе', { name: this.organization.businessName }),
        resolveText: this.$t('отключиться'),
        resolveButtonColor: 'error',
        rejectText: this.$t('отменить'),
        persistent: true,
      }).then(() => {
        this.deleteMyRole({
          cashRegister: this.cashRegister,
          id: this.user.id,
        }).then(() => {
          this.initCashRegisters().then(() => {
            this.showSnackbar({ message: this.$t('вы_успешно_отключились_от_кассы') })
            this.$router.push('/')
          })
        }).catch((error) => {
          this.showSnackbar({ message: this.$t('произошла_ошибка', { error }) })
        })
      }).catch(() => {})
    },

    save() {
      this.savePreferences({
        cashRegister: this.cashRegister,
        preferences: this.preferences,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id)
      }).catch((error) => {
        this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_сохранить_настройки', { error }) })
        this.selectCashRegister(this.cashRegister.id)
      })
    },

    downloadRegistrationCard() {
      this.$openLink(this.regCardUrl, '_system')
    },

    reRegistrate() {
      const code = this.cashRegister.data.preferences?.ofd?.rereg?.status?.code
      if (code === 'SUCCESS') {
        this.preferences.ofd.rereg.status.code = 'FINISHED'
        this.save()
      }

      this.showMiniApp({
        url: process.env.VUE_APP_MINIAPP_OFD_URL,
        name: process.env.VUE_APP_MINIAPP_OFD_NAME,
        params: [
          { mode: 'rereg' },
        ],
      })
    },

    // ---------------------------------------------- Снятие кассы с учета ----------------------------------------------
    showDeRegistratePinpad() {
      if (this.isShiftClosed()) {
        const code = this.cashRegister.data.preferences?.ofd?.dereg?.status?.code

        // Если есть определенный статус, то сразу показываем диалог
        if (['IN_PROGRESS', 'SENT', 'SUCCESS'].includes(code)) {
          this.deRegistrate()
        } else {
        // Иначе запрашиваем ПИН-код
          this.deregistrationDialog = true
        }
      }
    },

    // Костыль так как нет метода для проверки ПИН-кода
    checkPincode(pincode) {
      this.loading = true
      this.saveConfiguration({
        cashRegister: this.cashRegister,
        configuration: this.configuration,
        password: pincode,
      }).then(() => {
        this.loading = false
        this.deregistrationDialog = false
        this.deRegistrate(pincode)
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.deregistrationErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка', { error }) })
          this.loading = false
          this.deregistrationDialog = false
        }
      })
    },

    deRegistrate(pincode) {
      this.showMiniApp({
        url: process.env.VUE_APP_MINIAPP_OFD_URL,
        name: process.env.VUE_APP_MINIAPP_OFD_NAME,
        params: [
          { mode: 'dereg' },
          { pincode },
        ],
      })
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.configurationChanged) {
      this.showConfirm({
        title: this.$t('внесенные_изменения_не_сохранены_хотите_сохранить_изменения'), resolveText: this.$t('сохранить'), rejectText: this.$t('отменить'),
      }).then(() => {
        this.showSaveChangesPinpad()
      }).catch(() => {
        next()
      })
    } else {
      next()
    }
  },
}
</script>
