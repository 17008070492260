<template lang="pug">
div(v-if="isOpen")
  .anketolog-widget__container
    v-btn.anketolog-widget__button--close(@click="close" icon color="#000")
      v-icon mdi-close
    div.anketolog-widget(:id="tagID")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getLocale } from '../../../i18n'

export default {
  name: 'Anketolog',

  data: () => ({
    isOpen: false,
    tagID: null,
    widgetAPIsrc: null,
    widgetScript: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),

    locale() {
      return getLocale()
    },
  },

  created() {
    if (this.cashRegister.status === 'REGISTERED') {
      setTimeout(() => {
        this.checkForSurvey()
      }, 1000)
    }
  },

  methods: {
    ...mapActions({
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    checkForSurvey() {
      this.firebaseRemoteConfigGetString('ANKETOLOG_SURVEY').then((result) => {
        try {
          const surveyJSON = JSON.parse(result)[this.locale]
          if (surveyJSON) {
            this.tagID = surveyJSON.tag
            this.widgetAPIsrc = `${surveyJSON.src}&uid=${this.user.uid}`

            if (!localStorage.getItem(`rekassa.kz-anketolog-${this.tagID}`)) {
              this.open()
              localStorage.setItem(`rekassa.kz-anketolog-${this.tagID}`, 'true')
              this.analyticsLogEvent({ eventName: 're_anketolog_showed' })
            }
          }
        } catch (error) {
          // console.error('Error parsing ANKETOLOG_SURVEY', error)
        }
      })
    },

    open() {
      if (this.widgetScript === null) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = this.widgetAPIsrc
        document.body.appendChild(script)
        this.widgetScript = script
        this.isOpen = true
      }
    },

    close() {
      this.isOpen = false
      if (this.widgetScript) {
        document.body.removeChild(this.widgetScript)
        this.widgetScript = null
      }
    },
  },

}
</script>

<style lang="stylus">
.anketolog-widget__container
  position fixed
  z-index 99999
  width 100%
  height 100svh
  background-color #ffffff
  top 0
  left 0
  overflow-y auto

.anketolog-widget__button--close
  position absolute
  right 0px
  top 0px
  width 64px
  height 64px
  background transparent
  outline none
  border none
  display flex
  justify-content center
  align-items center
  color black
</style>
