<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$goBack()")
        v-icon mdi-arrow-left
      v-toolbar-title(v-if="ticket") {{ $t("чек") }} № {{ ticket.shiftDocumentNumber }}
      v-spacer
      v-btn.mr-4(v-if="showTicketDebugButton" color="warning" outlined :loading="ticketDebugDialogLoading" @click="showTicketDebugDialog()") JSON
      v-btn(outlined @click="changeTicketLocale()") {{ ticketNextLocale === 'ru' ? 'РУС' : (ticketNextLocale === 'kk' ? 'ҚАЗ' : 'ҚАЗ/РУС') }}
    v-progress-linear(:active="ticketLoading" :indeterminate="ticketLoading" fixed top color="secondary")

  v-main(flat v-if="ticket === null")
    v-container.skeleton.re-max-width-400
      v-skeleton-loader.mt-5.mx-auto(type="text" style="width: 150px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" style="width: 200px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" style="width: 200px;")

      v-skeleton-loader.mt-3.mx-auto(type="image" style="width: 120px; height: 120px;")

      v-skeleton-loader.mt-8.mx-auto(type="text" style="width: 140px;")

      v-skeleton-loader.mt-5.mx-auto(type="text")
      v-skeleton-loader.mt-3.mx-auto(type="text")
      v-skeleton-loader.mt-3.mx-auto(type="text")
      v-skeleton-loader.mt-3.mx-auto(type="text")
      v-skeleton-loader.mt-3.mx-auto(type="text")
      v-skeleton-loader.mt-3.mx-auto(type="text")

      v-divider.my-3

      v-skeleton-loader.mt-5(type="text" style="width: 100px;")
      v-skeleton-loader.mt-3.mx-auto(type="text")
      v-skeleton-loader.mt-3.mx-auto(type="text")

      v-divider.my-3

      v-skeleton-loader.mt-5.mx-auto(type="text")
      v-skeleton-loader.mt-3.mx-auto(type="text")

      v-divider.my-3

      v-skeleton-loader.mt-5.mx-auto(type="text" style="width: 200px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" style="width: 220px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" style="width: 180px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" style="width: 250px;")

  re-receipt(v-if="ticket" :ticket="ticket" :class="rollWidthClass" :cashRegister="cashRegister" :organization="organization" :ticketLocale="ticketLocale" :preTicket="false")

  v-bottom-navigation(app)
    v-btn(:min-width="minButtonWidth" v-if="$isCordova()" icon @click="shareSystem()" :loading="shareLoading" :disabled="ticketLoading")
      v-icon mdi-share-variant

    v-btn(:min-width="minButtonWidth" icon @click="downloadReceipt()" :loading="downloadLoading" :disabled="ticketLoading")
      v-icon mdi-download

    v-dialog(v-model="shareWhatsAppDialog" persistent max-width="400px")
      template(v-slot:activator="{ on }")
        v-btn(:min-width="minButtonWidth" :disabled="ticketLoading || !isTicketShareable" icon v-on="on" v-if="!$isFFBMode()")
          v-icon mdi-whatsapp
      v-card
        v-card-title
          span.headline {{ $t("отправить_чек_на_whatsapp") }}
        v-card-text
          v-container
            v-form(ref="shareWhatsAppForm" @submit.prevent="shareWhatsApp()")
              p {{ $t("можно_отправить_чек_не_сохраняя_номер_получателя_в_контакты") }}
              v-text-field(ref="mobileNumber"
                          v-if="mobileNumberFieldEnabled"
                          v-model="mobileNumber"
                          v-mask="'+7 (###) ###-##-##'"
                          type="tel"
                          :label="$t('номер_телефона')"
                          placeholder="+7 (###) ###-##-##"
                          prepend-inner-icon="mdi-phone"
                          autocomplete="off"
                          @input="mobileNumberError = null"
                          :error-messages="mobileNumberError"
                          clearable
                          :autofocus="!$isCordova()")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="shareWhatsAppDialog = false") {{ $t("отменить") }}
          v-btn(text @click="shareWhatsApp()" color="primary") {{ $t("отправить") }}

    v-dialog(v-model="shareEmailDialog" persistent max-width="400px")
      template(v-slot:activator="{ on }")
        v-btn(:min-width="minButtonWidth" :disabled="ticketLoading || !isTicketShareable" icon v-on="on")
          v-icon mdi-email
      v-card
        v-card-title
          span.headline {{ $t("отправить_чек_на_почту") }}
        v-card-text
          v-container
            v-form(ref="shareEmailForm" @submit.prevent="shareEmail()")
              p {{ $t("мы_отправим_этот_чек_на_указанный_вами_адрес_электронной_почты") }}
              v-text-field(v-model="email" type="email" :rules="[rules.email]" :label="$t('электронная_почта')" placeholder="client@rekassa.kz" prepend-inner-icon="mdi-at" autocomplete="off" clearable :validate-on-blur='shareEmailValidateOnBlur' :autofocus="!$isCordova()")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="shareEmailDialog = false") {{ $t("отменить") }}
          v-btn(text @click="shareEmail()" color="primary") {{ $t("отправить") }}

    v-btn(:min-width="minButtonWidth" :disabled="ticketLoading" icon @click="print()" :loading="printingLoading")
      v-icon mdi-printer

    v-dialog(v-if="ticketDebug && showTicketDebugButton" v-model="ticketDebugDialog" max-width="600px")
      json-viewer(:value="ticketDebug" :expand-depth=10 sort expanded copyable)

</template>
<script>
import { mapState, mapActions } from 'vuex'
import html2canvas from 'html2canvas'
import download from 'downloadjs'
import { getLocale } from '../../i18n/index'
import store from '../../store/index'
import Receipt from './parts/Receipt.vue'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import ticketMixin from '../../mixins/ticketMixin'

export default {
  components: {
    're-receipt': Receipt,
  },

  mixins: [dictionaryMixin, ticketMixin],

  data: () => ({
    minButtonWidth: 80,

    mobileNumber: null,
    mobileNumberFieldEnabled: false,
    email: null,

    shareWhatsAppDialog: false,
    shareEmailDialog: false,

    mobileNumberError: null,
    shareEmailValidateOnBlur: true,

    shareLoading: false,
    downloadLoading: false,
    printingLoading: false,
    ticketLoading: false,

    ticketDebug: null,
    ticketDebugDialogLoading: false,
    ticketDebugDialog: false,
    showTicketDebugButton: process.env.VUE_APP_TEST === 'true' && window.cordova === undefined,

    ticketNextLocale: getLocale() === 'ru' ? 'kk' : 'ru',
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      ticket: state => state.cashRegisters.ticket,
      printerSettings: state => state.printer.settings,
      keyListenerEnabled: state => state.tools.keyListenerEnabled,
      ticketLocale: state => state.tools.ticketLocale,
    }),

    ticketData() {
      if (this.ticket) {
        return this.ticket.data.ticket ? this.ticket.data.ticket : this.ticket.data.moneyPlacement
      }
      return null
    },

    rollWidthClass() {
      return this.printerSettings.rollWidth === 58 ? 'roll-width-58mm' : 'roll-width-80mm'
    },

    isTicketShareable() {
      return this.ticket && this.ticket.data.command === 'COMMAND_TICKET'
    },
  },

  // Clear ticket after leave
  beforeRouteLeave(to, from, next) {
    this.setTicket(null)
    this.setTicketRequest(null)
    next()
  },

  watch: {
    shareWhatsAppDialog(val) {
      if (val) {
        const phone = this.ticket.extra.receipts?.find((r) => r.type === 'WHATSAPP')?.receiver.phone
        this.mobileNumber = phone || null
        // Костыль https://github.com/probil/v-mask/issues/518
        this.mobileNumberFieldEnabled = false
        this.$nextTick(() => { this.mobileNumberFieldEnabled = true })
        this.setKeyListenerStatus(false)
      } else {
        this.setKeyListenerStatus(true)
      }
    },

    shareEmailDialog(val) {
      if (val) {
        this.setKeyListenerStatus(false)
      } else {
        this.setKeyListenerStatus(true)
      }
    },
  },

  created() {
    this.fetchTicketIfNull()
    this.setTicketLocale(localStorage.getItem('rekassa.kz-ui-ticket-locale') || getLocale())
  },

  mounted() {
    window.addEventListener('keydown', this.keyListener, false)

    // Autoprint
    if (this.$route.query.autoprint === 'true') {
      setTimeout(() => {
        this.print()
      }, 500)
    }

    if (this.cashRegister.status === 'TRIAL') {
      setTimeout(() => {
        this.showConfirm({
          title: `${this.$t('внимание').toUpperCase()}!!!`,
          text: this.$t('вы_находитесь_в_режиме_демонстрации_функционала_кассы_выданный_чек_является_недействительным_активируйте_кассу_чтобы_выдавать_фискальные_чеки'),
          resolveText: this.$t('активировать'),
          rejectText: this.$t('позже'),
        }).then(() => {
          this.$router.push('/miniapp?name=ofd')
        }).catch(() => {})
      }, 1000)
    }
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyListener, false)
  },

  // Calculate size of bottom buttons
  beforeMount() {
    const width = document.documentElement.clientWidth / 5
    this.minButtonWidth = width > 79 ? 80 : width
  },

  methods: {
    ...mapActions({
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      setKeyListenerStatus: 'tools/setKeyListenerStatus',
      setTicket: 'cashRegisters/setTicket',
      setTicketRequest: 'cashRegisters/setTicketRequest',
      fetchTicket: 'cashRegisters/fetchTicket',
      shareTicket: 'cashRegisters/shareTicket',
      printReceipt: 'printer/printReceipt',
      setTicketLocale: 'tools/setTicketLocale',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    showTicketDebugDialog() {
      this.ticketDebugDialogLoading = true
      store.dispatch('cashRegisters/fetchTicket', { cashRegisterId: this.$route.params.cashRegisterId, ticketId: this.$route.params.ticketId }).then(result => {
        this.ticketDebug = result.data
        this.ticketDebugDialog = true
        this.ticketDebugDialogLoading = false
      }).catch(() => {
        this.ticketDebugDialogLoading = false
      })
    },

    keyListener(event) {
      if (this.keyListenerEnabled) {
        if (event.key === 'Backspace') {
          this.$goBack()
        } else if (event.key === 'Enter') {
          this.print()
        }
      }
    },

    fetchTicketIfNull() {
      if (store.state.cashRegisters.ticket === null) {
        this.ticketLoading = true
        store.dispatch('cashRegisters/fetchTicket', { cashRegisterId: this.$route.params.cashRegisterId, ticketId: this.$route.params.ticketId }).then(result => {
          store.dispatch('cashRegisters/setTicket', result.data).then(() => {
            this.ticketLoading = false
          })
        }).catch((error) => {
          if (error && error.response && error.response.status === 404) {
            this.showSnackbar({ message: this.$t('чек_не_существует') })
            this.$router.push('/')
          }
        })
      }
    },

    changeTicketLocale() {
      const localeModes = getLocale() === 'ru' ? ['ru', 'kk', 'kk_ru'] : ['kk', 'ru', 'kk_ru']
      const currentModeIndex = localeModes.indexOf(this.ticketLocale)
      const nextModeIndex = (currentModeIndex + 1) % 3
      this.setTicketLocale(localeModes[nextModeIndex])
      this.ticketNextLocale = localeModes[(nextModeIndex + 1) % 3]
      localStorage.setItem('rekassa.kz-ui-ticket-locale', localeModes[nextModeIndex])
    },

    downloadReceipt() {
      this.downloadLoading = true
      setTimeout(() => {
        const receipt = document.getElementsByClassName('receipt')[0]
        html2canvas(receipt, {
          backgroundColor: '#ffffff',
          y: 50,
          scale: 2,
          useCORS: true,
        }).then(canvas => {
          if (this.$isCordova()) {
            window.canvas2ImagePlugin.saveImageDataToLibrary(
              () => {
                this.showSnackbar({ message: this.$t('чек_успешно_сохранен_в_галерее') })
                this.share('DOWNLOAD', null, null).then(() => {}).catch(() => {})
                this.downloadLoading = false
              },
              (error) => {
                this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
                this.downloadLoading = false
              },
              canvas,
              `${this.$t('смена')} №${this.ticket.shiftNumber} ${this.$t('чек')} №${this.ticket.shiftDocumentNumber}`,
              80,
              `reKassa_${this.cashRegister.serialNumber}`,
            )
          } else {
            download(canvas.toDataURL('image/png'), `${this.cashRegister.serialNumber} ${this.$t('смена')} №${this.ticket.shiftNumber} ${this.$t('чек')} №${this.ticket.shiftDocumentNumber}`)
            this.share('DOWNLOAD', null, null).then(() => {}).catch(() => {})
            this.downloadLoading = false
          }
        }).catch((error) => {
          this.showSnackbar({ message: `${this.$t('не_удалось_cкачать_чек')}: ${error}` })
          this.downloadLoading = false
        })
      }, 0)
    },

    shareSystem() {
      const shareTicketType = localStorage.getItem('rekassa.kz-ui-shareTicketType') || 'IMAGE'
      if (shareTicketType === 'IMAGE') {
        this.shareLoading = true
        setTimeout(() => {
          const receipt = document.getElementsByClassName('receipt')[0]
          html2canvas(receipt, {
            backgroundColor: '#ffffff',
            y: 50,
            scale: 2,
            useCORS: true,
          }).then(canvas => {
            window.canvas2ImagePlugin.shareImageData(
              () => {
                this.share('SYSTEM', null, null).then(() => {}).catch(() => {})
                this.shareLoading = false
              },
              (error) => {
                this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
                this.shareLoading = false
              },
              canvas,
              80,
            )
          }, 0)
        })
      } else {
        navigator.share({
          title: this.$t('поделиться_чеком'),
          text: this.getMessageText(false),
        }).then(() => {
          this.share('SYSTEM', null, null).then(() => {}).catch(() => {})
        })
      }
    },

    shareWhatsApp() {
      if (this.$refs.mobileNumber.value?.length === 18) {
        this.$openLink(`https://api.whatsapp.com/send?phone=${this.mobileNumber.replace(/[^\d]/g, '')}&text=${this.getMessageText(true)}`, '_system')
        this.share('WHATSAPP', this.mobileNumber.replace(/[^\d]/g, ''), null).then(() => {}).catch(() => {})
        this.shareWhatsAppDialog = false
      } else {
        this.mobileNumberError = this.$t('введите_номер_телефона')
      }
    },

    shareEmail() {
      if (this.$refs.shareEmailForm.validate()) {
        this.share('EMAIL', null, this.email).then(() => {
          this.showSnackbar({ message: this.$t('чек_успешно_отправлен_на', { email: this.email }) })
        }).catch(() => {})
        this.shareEmailDialog = false
      } else {
        this.shareEmailValidateOnBlur = false
      }
    },

    getMessageText(encode) {
      return `${this.$t('отправил_вам_электронный_чек_на_сумму_от', this.ticketLocale, {
        posTitle: this.organization.businessName.replace('&', '%26'), number: (this.ticket.offline ? this.ticket.offlineTicketNumber : this.ticket.ticketNumber), sum: this.$options.filters.numeral(this.ticketData.amounts.total.value, '0,0.[00]'), date: this.$moment(this.ticketData.dateTime.value).format('DD/MM/YYYY'),
      })
      } ${this.$t('ссылка_на_чек', this.ticketLocale)}: ${encode ? encodeURIComponent(this.getQRCode()) : this.getQRCode()}`
    },

    getQRCode() {
      return this.ticket.fdoQrCode
    },

    share(type, phone, email) {
      const data = {
        type,
      }
      if (phone) {
        data.receiver = { phone }
      } else if (email) {
        data.receiver = { email }
      }
      this.analyticsLogEvent({ eventName: 're_share_ticket', eventProperties: { type } })
      return this.shareTicket({ cashRegister: this.cashRegister, ticket: this.ticket, data })
    },

    getBase64Image(url) {
      return new Promise((resolve, reject) => {
        try {
          const img = new Image()
          img.setAttribute('crossOrigin', 'anonymous')
          img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx.fillStyle = 'white'
            ctx.drawImage(img, 0, 0)
            const dataURL = canvas.toDataURL('image/png')
            resolve(dataURL)
          }
          img.src = url
        } catch (error) {
          reject(error)
        }
      })
    },

    print() {
      if (this.$isCordova()) {
        this.printCordova()
      } else {
        this.printWeb()
      }
    },

    printWeb() {
      window.print()
      this.share('PRINTER', null, null).then(() => {}).catch(() => {})
    },

    async printCordova() {
      this.printingLoading = true

      const printCmds = []
      if (['MONEY_PLACEMENT_DEPOSIT', 'MONEY_PLACEMENT_WITHDRAWAL'].indexOf(this.ticketData.operation) === -1) {
        if (this.preferences.logoUrl) {
          printCmds.push('#align_left#')
          printCmds.push('#logo#')
          printCmds.push(' ')
          printCmds.push('#new_line#')
        }

        if (this.preferences.advertisingText) {
          printCmds.push('#align_center#')
          printCmds.push(this.preferences.advertisingText)
          printCmds.push('#new_line#')
          printCmds.push('#line#')
        }
      }

      printCmds.push('#align_center#')
      printCmds.push(this.organization.businessName)
      printCmds.push('#new_line#')

      printCmds.push(`${this.$tre('бин_иин', this.ticketLocale)} ${this.organization.businessId}`)
      printCmds.push('#new_line#')

      printCmds.push(this.cashRegister.pos.address)
      printCmds.push('#new_line#')

      if (this.cashRegister.status === 'TRIAL' || process.env.VUE_APP_TEST === 'true') {
        printCmds.push(this.$tre('нефискальный_чек', this.ticketLocale).toUpperCase())
        printCmds.push('#new_line#')
        if (process.env.VUE_APP_TEST === 'true') {
          printCmds.push(this.$tre('для_тестирования', this.ticketLocale).toUpperCase())
        } else {
          printCmds.push(this.$tre('для_демонстрации', this.ticketLocale).toUpperCase())
        }
      } else {
        printCmds.push(('фискальный_чек', this.ticketLocale).toUpperCase())
      }

      printCmds.push('#new_line#')

      printCmds.push('#align_left#')

      if (this.ticket.offline) {
        printCmds.push(this.$tre('автономный', this.ticketLocale).toUpperCase())
        printCmds.push('#new_line#')
      }

      printCmds.push(`${this.$tre(`operationType.${this.ticketData.operation}`, this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(`#${this.ticket.shiftDocumentNumber}`)
      printCmds.push(`${this.$tre('смена', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.ticket.shiftNumber)

      printCmds.push('#new_line#')
      printCmds.push(`${this.$tre('дата', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(this.ticketData.dateTime.value).format('DD-MM-YYYY'))
      printCmds.push(`${this.$tre('время', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(this.ticketData.dateTime.value).format('HH:mm:ss'))
      printCmds.push('#new_line#')

      if (this.commodityItems) {
        printCmds.push(`${this.$tre('фп', this.ticketLocale)} `)
        printCmds.push('#half_line#')
        printCmds.push(this.ticket.offline ? this.ticket.offlineTicketNumber : this.ticket.ticketNumber)
      }
      printCmds.push(`${this.$tre('кассир', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      // eslint-disable-next-line no-nested-ternary
      printCmds.push(this.ticketData.operator ? (this.ticketData.operator.name ? this.ticketData.operator.name : this.ticketData.operator.code) : '')
      printCmds.push('#new_line#')

      printCmds.push(this.$tre('рнм', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.registrationNumber)
      printCmds.push('#new_line#')

      printCmds.push(this.$tre('знм', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.serialNumber)
      printCmds.push('#new_line#')

      printCmds.push('#line#')

      if (this.isDuplicate) {
        printCmds.push('#align_center#')
        printCmds.push(this.$tre('дубликат', this.ticketLocale).toUpperCase())
        printCmds.push('#new_line#')
        printCmds.push('#line#')
        printCmds.push('#align_left#')
      }

      if (this.commodityItems) {
        for (let index = 0; index < this.commodityItems.length; index += 1) {
          if (index !== 0) {
            printCmds.push('#new_line#')
          }

          const item = this.commodityItems[index]
          printCmds.push(`${index + 1}. ${this.preferences.barcodeInPositions && item.commodity.barcode ? `${item.commodity.barcode} ` : ''}${item.commodity.name}`)
          printCmds.push('#new_line#')

          if (item.commodity.sku) {
            printCmds.push(`${this.$tre('артикул', this.ticketLocale)}: ${item.commodity.sku}`)
            printCmds.push('#new_line#')
          }

          if (item.commodity.exciseStamp) {
            printCmds.push(item.commodity.exciseStamp)
            printCmds.push('#new_line#')
          }

          printCmds.push(`${this.$options.filters.numeral(item.commodity.realQuantity, '0,0.0[00]')} ${item.commodity.unitType ? this.$tre(`unitType.${item.commodity.unitType}`, this.ticketLocale) : `(${this.$tre('код_эсф')}: ${item.commodity.measureUnitCode})`} x ${this.$options.filters.numeral(item.commodity.price.value, '0,0.00')}₸ `)
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(item.commodity.sum.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')

          if (item.commodity.discountType) {
            printCmds.push(`${this.$tre('скидка', this.ticketLocale)} ${item.commodity.discountType === 'PERCENTAGE' ? `(${item.commodity.discountPercentage}%)` : ''}`)
            printCmds.push('#full_width#')
            printCmds.push(`-${this.$options.filters.numeral(item.commodity.discountValue, '0,0.00')}₸`)
            printCmds.push('#new_line#')
          }

          if (item.commodity.markupType) {
            printCmds.push(`${this.$tre('наценка', this.ticketLocale)} ${item.commodity.markupType === 'PERCENTAGE' ? `(${item.commodity.markupPercentage}%)` : ''}`)
            printCmds.push('#full_width#')
            printCmds.push(`+${this.$options.filters.numeral(item.commodity.markupValue, '0,0.00')}₸`)
            printCmds.push('#new_line#')
          }

          printCmds.push(this.$tre('стоимость', this.ticketLocale))
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(item.commodity.total, '0,0.00')}₸`)
          printCmds.push('#new_line#')

          if (item.commodity.taxes && item.commodity.taxes[0]) {
            printCmds.push(`${this.$tre('в_т_ч_ндс', this.ticketLocale)} ${item.commodity.taxes[0].percent / 1000}%`)
            printCmds.push('#full_width#')
            printCmds.push(`${this.$options.filters.numeral(item.commodity.taxes[0].sum.value, '0,0.00')}₸`)
            printCmds.push('#new_line#')
          }
        }
      }

      if (this.commodityItems && this.commodityItems.length > 0) {
        printCmds.push('#line#')
      }

      printCmds.push(this.$tre('итого', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push(`${this.$options.filters.numeral(this.ticketData.amounts.total.value, '0,0.00')}₸`)
      printCmds.push('#new_line#')

      if (this.ticketData.amounts.discount) {
        printCmds.push(this.$tre('сумма_без_скидки', this.ticketLocale))
        printCmds.push('#full_width#')
        printCmds.push(`${this.$options.filters.numeral(this.ticketData.amounts.discount.sumWithoutDiscount, '0,0.00')}₸`)
        printCmds.push('#new_line#')

        printCmds.push(`${this.$tre('скидка', this.ticketLocale)} ${this.ticketData.amounts.discount.type === 'PERCENTAGE' ? `(${this.ticketData.amounts.discount.percentage}%)` : ''}`)
        printCmds.push('#full_width#')
        printCmds.push(`-${this.$options.filters.numeral(this.ticketData.amounts.discount.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')
      }

      if (this.ticketData.amounts.markup) {
        printCmds.push(this.$tre('сумма_без_наценки', this.ticketLocale))
        printCmds.push('#full_width#')
        printCmds.push(`${this.$options.filters.numeral(this.ticketData.amounts.markup.sumWithoutMarkup, '0,0.00')}₸`)
        printCmds.push('#new_line#')

        printCmds.push(`${this.$tre('наценка', this.ticketLocale)} ${this.ticketData.amounts.markup.type === 'PERCENTAGE' ? `(${this.ticketData.amounts.markup.percentage}%)` : ''}`)
        printCmds.push('#full_width#')
        printCmds.push(`+${this.$options.filters.numeral(this.ticketData.amounts.markup.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')
      }

      if (this.otherPayments) {
        for (let index = 0; index < this.otherPayments.length; index += 1) {
          const payment = this.otherPayments[index]
          printCmds.push(this.$tre(`paymentType.${payment.type}`, this.ticketLocale))
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(payment.sum.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')

          if (payment.type === 'PAYMENT_MOBILE' && this.ticket.extra && this.ticket.extra.attributes && this.ticket.extra.attributes.paymentType === 'KASPI_QR') {
            printCmds.push(`${this.$tre('оплачено_через_name', this.ticketLocale, { name: this.$tre('KASPI_QR', this.ticketLocale) })}`)
            printCmds.push('#new_line#')
          }

          if (payment.type === 'PAYMENT_CARD' && this.ticket.extra && this.ticket.extra.attributes && this.ticket.extra.attributes.paymentType) {
            printCmds.push(`${this.$tre(`${this.ticketData.operation === 'OPERATION_SELL' ? 'оплачено_через_name' : 'возврат_через_name'}`, this.ticketLocale, { name: this.$tre(this.ticket.extra.attributes.paymentType, this.ticketLocale) })}`)
            printCmds.push('#new_line#')
          }

          if (payment.type === 'PAYMENT_CARD' && this.ticket.extra && this.ticket.extra.attributes && (this.ticket.extra.attributes.paymentType === 'ALFA_PAY' || this.ticket.extra.attributes.paymentType === 'HALYK_POS' || this.ticket.extra.attributes.paymentType === 'FFB_POS')) {
            printCmds.push(this.$tre('номер_операции', this.ticketLocale))
            printCmds.push('#full_width#')
            if (this.ticket.extra.attributes.m4bankOperationDay) {
              printCmds.push(`${this.ticket.extra.attributes.m4bankOperationDay}/${this.ticket.extra.attributes.m4bankTransactionNumber}`)
            } else {
              printCmds.push(this.ticket.extra.attributes.m4bankTransactionNumber)
            }
            printCmds.push('#new_line#')

            printCmds.push(this.$tre('дата_оплаты', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(this.$moment(this.ticket.extra.attributes.m4bankDateTime).format('DD-MM-YYYY HH:mm:ss'))
            printCmds.push('#new_line#')

            printCmds.push(this.$tre('реквизиты_карты', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(this.ticket.extra.attributes.m4bankMaskedPan)
            printCmds.push('#new_line#')

            printCmds.push(this.$tre('организация', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(` ${this.ticket.extra.attributes.m4bankMerchantName}`)
            printCmds.push('#new_line#')

            printCmds.push(this.$tre('терминал', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(this.ticket.extra.attributes.m4bankTerminalId)
            printCmds.push('#new_line#')

            printCmds.push(this.$tre('код_ответа', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push('0')
            printCmds.push('#new_line#')

            printCmds.push(this.$tre('код_авторизации', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(this.ticket.extra.attributes.m4bankAuthorizationCode)
            printCmds.push('#new_line#')

            printCmds.push(this.$tre('rrn', this.ticketLocale))
            printCmds.push('#full_width#')
            printCmds.push(this.ticket.extra.attributes.m4bankRRN)
            printCmds.push('#new_line#')
          }
        }
      }

      if (this.ticketData.amounts.taken && this.ticketData.amounts.taken.value > 0) {
        printCmds.push(this.$tre('paymentType.PAYMENT_CASH', this.ticketLocale))
        printCmds.push('#full_width#')
        printCmds.push(`${this.$options.filters.numeral(this.ticketData.amounts.taken.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')
      }

      if (this.ticketData.amounts.change && this.ticketData.amounts.change.value > 0) {
        printCmds.push(this.$tre('сдача', this.ticketLocale))
        printCmds.push('#full_width#')
        printCmds.push(`-${this.$options.filters.numeral(this.ticketData.amounts.change.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')
      }

      if (this.taxes) {
        for (let index = 0; index < this.taxes.length; index += 1) {
          const tax = this.taxes[index]
          printCmds.push(`${this.$tre('в_т_ч_ндс', this.ticketLocale)} ${tax.percent}%`)
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(tax.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')
        }
      }

      if (this.ticketData.extensionOptions && this.ticketData.extensionOptions.customerIinOrBin) {
        printCmds.push('#line#')
        printCmds.push(this.$tre('покупатель', this.ticketLocale))
        printCmds.push('#full_width#')
        printCmds.push(this.ticketData.extensionOptions.customerIinOrBin)
        printCmds.push('#new_line#')
      }

      if (this.orderNumber()) {
        printCmds.push('#line#')
        printCmds.push(this.$tre('номер_заказа', this.ticketLocale))
        printCmds.push('#full_width#')
        printCmds.push(this.orderNumber())
        printCmds.push('#new_line#')
      }

      if (this.ticketData.extensionOptions && this.ticketData.extensionOptions.customerEmail) {
        printCmds.push('#line#')
        printCmds.push(this.$tre('email', this.ticketLocale))
        printCmds.push('#full_width#')
        printCmds.push(this.ticketData.extensionOptions.customerEmail)
        printCmds.push('#new_line#')
      }

      if (this.ticket.offline ? this.ticket.offlineTicketNumber : this.ticket.ticketNumber) {
        printCmds.push('#line#')

        if (this.ticket.qrCode) {
          printCmds.push('#qr_code#')
        }

        if (this.ticket.fdo) {
          printCmds.push('#align_center#')
          printCmds.push(this.ticketLocale === 'ru' ? this.ticket.fdo.nameRu : this.ticket.fdo.nameKk)
          printCmds.push('#new_line#')
          printCmds.push(`${this.$tre('для_проверки_чека_отсканируйте_qr_код', this.ticketLocale)} (${this.ticket.fdo.url})`)
          printCmds.push('#new_line#')
        }
      }

      printCmds.push('#line#')
      printCmds.push('#align_center#')
      printCmds.push(this.$tre('rekassakz_бесплатный_онлайн_кассовый_аппарат', this.ticketLocale))
      printCmds.push('#new_line#')

      const payload = {
        cmds: printCmds,
      }

      if (['MONEY_PLACEMENT_DEPOSIT', 'MONEY_PLACEMENT_WITHDRAWAL'].indexOf(this.ticketData.operation) === -1) {
        payload.qrCode = this.getQRCode()

        if (this.preferences.logoUrl && !this.$isFFBMode()) {
          const logoBase64 = await this.getBase64Image(this.preferences.logoUrl)
          payload.logoBase64 = logoBase64.replace(/^data:image\/[a-z]+;base64,/, '')
        }
      }

      this.printReceipt(payload).then(() => {
        this.share('PRINTER', null, null).then(() => {}).catch(() => {})
        setTimeout(() => { this.printingLoading = false }, 500)
      }, (error) => {
        if (error && error.message === 'printerNotSelected') {
          this.showConfirm({
            title: this.$tr('необходимо_выбрать_принтер'),
            text: this.$tr('прежде_чем_напечатать_чек_необходимо_выбрать_принтер_dotdotdot'),
            resolveText: this.$tr('перейти'),
            rejectText: this.$tr('отменить'),
          }).then(() => {
            this.$router.push('/printer/settings')
          }).catch(() => {})
        }
        this.printingLoading = false
      })
    },
  },
}
</script>

<style lang="stylus">
</style>
