<template lang="pug">
div
  re-stories

  v-container.mt-1.mt-md-8.mb-3
    re-shift-and-miniapps

    re-analytics

    re-services

    re-promotions

  re-anketolog

</template>
<script>
import { mapActions } from 'vuex'
import Stories from './parts/Stories.vue'
import ShiftAndMiniApps from './parts/ShiftAndMiniApps.vue'
import Promotions from './parts/Promotions.vue'
import Anketolog from './parts/Anketolog.vue'
import Analytics from './parts/Analytics.vue'
import Services from './parts/Services.vue'

export default {
  components: {
    're-stories': Stories,
    're-shift-and-miniapps': ShiftAndMiniApps,
    're-analytics': Analytics,
    're-services': Services,
    're-promotions': Promotions,
    're-anketolog': Anketolog,
  },

  beforeRouteEnter(to, from, next) {
    // Если FreedomBox или Мой учет iframe то перенаправляем на /kkm
    if (process.env.VUE_APP_FFB_POS_MODE === 'true' || window.location !== window.parent.location) {
      next('/kkm')
    } else {
      next()
    }
  },

  mounted() {
    this.analyticsLogEvent({ eventName: 're_dash_opened' })
  },

  methods: {
    ...mapActions({
      analyticsLogEvent: 'analytics/logEvent',
    }),
  },
}
</script>
<style scoped lang="stylus">
</style>
