export function initDeepLinkHandling(router) {
  const routes = {
    signin: '/signin',
    main: '/main',
    list: '/list',
    'payment-methods': '/kkm/fdo/payment-methods',
    'kkm-settings': '/kkm/settings',
    miniapp: '/miniapp',
  }

  Object.keys(routes).forEach((key) => {
    window.cordova.plugins.UniversalLinks.subscribe(key, (eventData) => {
      const query = Object.entries(eventData.params)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&')
      router.push(`${routes[key]}?${query}`, () => {})
    })
  })
}

export function isDeepLink(url) {
  if (url.endsWith('.html')) return false
  const pattern = new RegExp(`^${process.env.VUE_APP_API_ROOT_URL}`)
  return pattern.test(url)
}

export function getDeepLinkRoute(url) {
  return url.replace(process.env.VUE_APP_API_ROOT_URL, '')
}
