<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(:disabled="registerLoading" icon @click="$goBack()")
        v-icon mdi-arrow-left
      v-spacer
      template(v-if="!$isFFBMode()")
        v-btn.mr-2.text-h5.white--text(v-if="halykPosAllowed" :disabled="registerLoading || requestSent" @click="showPinpad('HALYK_POS')" color="green" width="110") Halyk Pos
        v-btn.mr-2.text-h5.white--text(v-if="kaspiQRAllowed" :disabled="registerLoading || requestSent" @click="showPinpad('KASPI_QR')" color="red" width="110") Каспи QR

    v-progress-linear(:active="registerLoading" :indeterminate="registerLoading" fixed top color="secondary")

  re-receipt(:ticket="ticket" :cashRegister="cashRegister" :organization="organization" :ticketLocale="getLocale()" :preTicket="true" style="margin-bottom: 180px;")

  div.fixed-bottom
    div
      v-btn.title.white--text(:disabled="registerLoading" @click="showPinpad()" block large color="secondary" class="mb-4") {{ selectedOperationType ? `${$t('operationType.' + selectedOperationType)} ${$options.filters.numeral(totalSum, '0,0.[00]')} ₸` : '-----------------' }}
    div(style="text-align: center;")
      v-btn.subtitle-1.text-decoration-underline(:disabled="registerLoading || requestSent" @click="operationTypeDialog = true" text color="primary") {{ $t("другие_операции") }}

  v-bottom-sheet(v-model="operationTypeDialog")
    v-list(subheader)
      v-subheader {{ $t("тип_операции") }}
      v-list-item(v-for="(operationType, $index) in filteredOperationTypes" :key="operationType.title" @click="selectOperationType(operationType)")
        v-list-item-avatar
          v-avatar(size="32px" tile)
            v-icon {{ operationType.icon }}
        v-list-item-title {{ $index + 1 }}. {{ operationType.title }}
      v-list-item.mt-3(color="primary" @click="operationTypeDialog = false")
        v-list-item-avatar
          v-avatar(size="32px" tile)
            v-icon mdi-close
        v-list-item-title {{ $t("отмена") }}

  re-pinpad(v-model="cashRegisterPinpadDialog" :title="$t('фискализация_чека')" :subtitle="$t('введите_пин-код')" :text="$t('для_фискализации')" :loading="cashRegisterPinpadLoading" :errorText.sync="cashRegisterPinpadErrorMessage" @action="checkPin")

  v-dialog(v-model="m4bankErrorDialog" scrollable fullscreen persistent width="500")
    v-card
      v-container
        v-row.mt-10
          v-col(align="center")
            v-icon(size="180" color="error") mdi-alert-circle-outline

        v-row.mt-10
          v-col
            .title.text-center {{ m4bankErrorText }}

        v-row.mt-10
          v-col(align="center")
            v-btn(large color="error" width="250" @click="m4bankErrorDialog = false") {{ $t("закрыть") }}

  v-dialog(v-model="ffbPosReversalRrnDialog" persistent)
    v-card
      v-card-title.text-center
        span {{ $t("возврат_платежной_транзакции") }}
      v-card-text.pt-0.pb-2
        v-form(ref="ffbPosReversalForm")
          v-container
            v-row
              v-col
                v-text-field(:label="$t('введите_код_авторизации')" v-model="ffbPosReversalAuthCode" type="tel" :rules="[rules.length(6)]" maxlength="6" counter validate-on-blur autocomplete="off" clearable)
                v-text-field(:label="$t('введите_rrn')" v-model="ffbPosReversalRrnCode" type="tel" :rules="[rules.length(12)]" maxlength="12" counter validate-on-blur autocomplete="off" clearable)

          v-row.mt-5
            v-col(align="center")
              v-btn.mb-6(color="primary" width="250" @click="ffbPosReversal()") {{ $t("возврат") }}
              a.caption(@click="skipFfbPosReversal()") {{ $t("пропустить") }}

  v-dialog(v-model="kaspiQRDialog" persistent max-width="400px" :fullscreen="$vuetify.breakpoint.xsOnly")
    v-card
      v-card-title.pa-0.pt-3.pr-3
        v-toolbar(dense flat)
          v-spacer
          v-btn(icon @click="kaspiQRDialog = false")
            v-icon mdi-close

      v-container
        v-row
          v-col
            .re-number-font.text-h1.text-center {{ totalSum | numeral('0,0.[00]') }} ₸

        v-row.mt-4
          v-col
            v-row(justify="center")
              img.kaspi-qrcode-img(src="/static/icons/kaspi-qr-small.png" height="36" width="36")
              div.kaspi-corners
                div.kaspi-qrcode
                  qrcode(:value="kaspiQR" size="140" level="H" renderAs="svg")

        v-row.mt-6
          v-col(cols="10" offset="1")
            v-list(style="background-color: var(--v-reBackground-base) !important")
              v-list-item
                v-list-item-icon
                  img.ml-4.mr-2(src="/static/icons/kaspi-qr.png" height="30" width="30")
                v-list-item-content
                  v-list-item-title.font-weight-bold Каспи QR
                  v-list-item-title {{ $t("сканируйте_и_платите") }}

        v-row.my-10
          v-col(align="center")
            v-btn.text-h3(large color="secondary" max-width="300" @click="kaspiQRPaid()") {{ $t("фискализировать_чек") }}

</template>
<script>
import fdo from '@comrun/kfdo'
import { Decimal } from 'decimal.js'
import { mapState, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import qrcode from 'qrcode.vue'
import { getLocale } from '../../i18n/index'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'
import store from '../../store/index'
import Receipt from './parts/Receipt.vue'
import Pinpad from '../utils/PinpadDialog.vue'

export default {
  components: {
    qrcode,
    're-receipt': Receipt,
    're-pinpad': Pinpad,
  },

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    uuidString: null,
    selectedOperationType: null,
    ticketRequest: null,
    registerLoading: false,
    operationTypeDialog: false,
    taken: null,
    flow: null,

    cashRegisterPinpadDialog: false,
    cashRegisterPinpadLoading: false,
    cashRegisterPinpadErrorMessage: null,

    requestSent: false,

    m4bankType: null,
    m4bankAttributes: null,
    m4bankErrorDialog: false,
    m4bankErrorText: null,

    ffbPosReversalRrnDialog: false,
    ffbPosReversalAuthCode: null,
    ffbPosReversalRrnCode: null,

    kaspiQRDialog: false,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
      cashRegisterPinChecked: state => state.cashRegisters.cashRegisterPinChecked,
      hasNFC: state => state.m4bank.hasNFC,
      hasAndroid8AndAbove: state => state.m4bank.hasAndroid8AndAbove,
      printerSettings: state => state.printer.settings,
    }),

    filteredOperationTypes() {
      return this.operationTypes.filter((operationType) => (this.preferences.buyMode ? true : (!(operationType.value === 'OPERATION_BUY' || operationType.value === 'OPERATION_BUY_RETURN'))))
    },

    ticket() {
      return {
        data: {
          ticket: this.ticketRequest.toJSON(),
        },
      }
    },

    totalSum() {
      return this.getNumberFromBillsAndCoins(this.$store.state.cashRegisters.ticketRequest.amounts.total)
    },

    halykPosAllowed() {
      return this.selectedOperationType === 'OPERATION_SELL' && this.configuration.halyk && this.configuration.halyk.halykPosConnected
    },

    kaspiQRAllowed() {
      return this.selectedOperationType === 'OPERATION_SELL' && this.configuration.kaspiQR && this.configuration.kaspiQR.connected
    },

    kaspiQR() {
      return this.configuration?.kaspiQR?.qr
    },
  },

  created() {
    this.uuidString = uuidv4()
    this.ticketRequest = this.$store.state.cashRegisters.ticketRequest
    this.selectedOperationType = this.ticketRequest.operation === 2 ? 'OPERATION_SELL' : null
    // Backup taken
    this.taken = this.ticketRequest.amounts.taken
  },

  beforeRouteEnter(to, from, next) {
    if (store.state.cashRegisters.ticketRequest) {
      next()
    } else {
      next('/kkm')
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.name !== 'TicketPrint' && this.registerLoading) {
      this.showSnackbar({ message: this.$t('пожалуйста_дождитесь_окончания_процесса') })
      next(false)
    } else {
      next()
    }
  },

  mounted() {
    window.addEventListener('keydown', this.keyListener, false)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyListener, false)
  },

  methods: {
    ...mapActions({
      registerTicket: 'cashRegisters/registerTicket',
      registerCash: 'cashRegisters/registerCash',
      setTicketRequest: 'cashRegisters/setTicketRequest',
      setTicket: 'cashRegisters/setTicket',
      setTicketBulkAttributes: 'cashRegisters/setTicketBulkAttributes',
      checkPassword: 'cashRegisters/checkPassword',
      setCashRegisterPinChecked: 'cashRegisters/setCashRegisterPinChecked',
      m4bankRequestOperation: 'm4bank/requestOperation',
      ffbPosPayment: 'ffbPos/payment',
      ffbPosRefund: 'ffbPos/refund',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    getLocale() {
      return getLocale()
    },

    keyListener(event) {
      if (!this.cashRegisterPinpadDialog && !this.registerLoading && !this.ffbPosReversalRrnDialog) {
        const reg = new RegExp('^[1-7]$')
        if (event.key === 'Backspace') {
          this.$router.push('/details')
        } else if (event.key === ' ') {
          this.operationTypeDialog = true
        } else if (event.key === 'Escape') {
          this.operationTypeDialog = false
        } else if (reg.test(event.key)) {
          const operationType = this.filteredOperationTypes[event.key - 1]
          if (operationType && !this.requestSent) {
            this.selectOperationType(operationType)
          }
        } else if (event.key === 'Enter') {
          this.showPinpad()
        }
      }
    },

    processItemsForAnalytics(result) {
      if (result.data?.data?.ticket?.operation === 'OPERATION_SELL') {
        result.data?.data?.ticket?.items.forEach((item) => {
          if (item.commodity) {
            this.analyticsLogEvent({
              eventName: 're_ticket_registered_commodity_name',
              eventProperties: {
                type:
              item.commodity.name === this.preferences.defaultItemName ? 'default' : 'unique',
              },
            })
          }
        })
      result.data?.data?.ticket?.payments.forEach((item) => {
        this.analyticsLogEvent({
          eventName: 're_ticket_registered_payment_type',
          eventProperties: { type: item.type },
        })
      })
      }
    },

    selectOperationType(operationType) {
      this.ticketRequest = this.$store.state.cashRegisters.ticketRequest

      const hasChange = this.ticketRequest.amounts && this.ticketRequest.amounts.change && this.getNumberFromBillsAndCoins(this.ticketRequest.amounts.change) > 0
      let hasItemDiscount = false
      let hasItemMarkup = false
      if (this.ticketRequest.items) {
        this.ticketRequest.items.forEach(item => {
          if (item.type === fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_COMMODITY) {
            hasItemDiscount = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT') !== undefined
            hasItemMarkup = item.commodity.auxiliary.find((aux) => aux.key === 'MARKUP') !== undefined
          }
        })
      }

      if (hasChange && (operationType.value === 'OPERATION_SELL_RETURN' || operationType.value === 'OPERATION_BUY')) {
        this.selectedOperationType = null
        this.showSnackbar({ message: this.$t('сдача_не_допустима_в_операции', { operation: operationType.title }) })
        this.operationTypeDialog = false
        return
      }

      if (['MONEY_PLACEMENT_DEPOSIT', 'MONEY_PLACEMENT_WITHDRAWAL'].indexOf(operationType.value) >= 0 && (hasChange || hasItemDiscount || hasItemMarkup)) {
        this.selectedOperationType = null
        this.showSnackbar({ message: this.$t('скидка_наценка_и_сдача_не_допустимы_в_операции', { operation: operationType.title }) })
        this.operationTypeDialog = false
        return
      }

      if (['OPERATION_SELL', 'OPERATION_SELL_RETURN', 'OPERATION_BUY', 'OPERATION_BUY_RETURN'].indexOf(operationType.value) >= 0) {
        this.ticketRequest.operation = fdo.kkm.proto.OperationTypeEnum[operationType.value]
        this.selectedOperationType = operationType.value
        this.operationTypeDialog = false

        // Exception for OPERATION_SELL_RETURN
        if (operationType.value === 'OPERATION_SELL_RETURN' || operationType.value === 'OPERATION_BUY') {
          this.ticketRequest.amounts.taken = this.getBillsAndCoinsMoney(0)
        } else {
          // Restoring from backup
          this.ticketRequest.amounts.taken = this.taken
        }
      } else if (['MONEY_PLACEMENT_DEPOSIT', 'MONEY_PLACEMENT_WITHDRAWAL'].indexOf(operationType.value) >= 0) {
        const moneyPlacemenTicketRequest = new fdo.kkm.proto.MoneyPlacementRequest()
        moneyPlacemenTicketRequest.operation = fdo.kkm.proto.MoneyPlacementEnum[operationType.value]
        moneyPlacemenTicketRequest.sum = this.getBillsAndCoinsMoney(this.totalSum)
        this.selectedOperationType = operationType.value
        this.ticketRequest = moneyPlacemenTicketRequest
        this.operationTypeDialog = false
      }
    },

    // Start
    showPinpad(type) {
      this.flow = this.$isFFBMode() && (this.selectedOperationType === 'OPERATION_SELL' || this.selectedOperationType === 'OPERATION_SELL_RETURN') && this.ticketRequest.payments.find((p) => p.type === fdo.kkm.proto.PaymentTypeEnum.PAYMENT_CARD) ? 'FFB_POS' : type || 'DEFAULT'

      // Check if operation type selected
      if (this.selectedOperationType === null) {
        this.showSnackbar({ message: this.$t('выберите_тип_операции') })
        return
      }

      // Check if shift expired
      if (this.checkIfShiftExpired()) return

      // Check Halyk POS requirements
      if (this.flow === 'HALYK_POS') {
        if (!this.hasAndroid8AndAbove || !this.hasNFC) {
          this.showSnackbar({ message: this.$t('для_того_чтобы_начать_принимать_бесконтактные_платежи_требуется_dotdotdot') })
          return
        }

        const cardPayment = this.ticketRequest.payments.find((p) => p.type === fdo.kkm.proto.PaymentTypeEnum.PAYMENT_CARD)
        if (cardPayment === undefined) {
          this.showSnackbar({ message: this.$t('нет_суммы_для_оплаты_картой') })
          return
        }

        this.m4bankType = 'HALYK_POS'
        this.m4bankAttributes = null
      }

      // Check Kaspi QR requirements
      if (this.flow === 'KASPI_QR') {
        const cardPayment = this.ticketRequest.payments.find((p) => p.type === fdo.kkm.proto.PaymentTypeEnum.PAYMENT_MOBILE)
        if (cardPayment === undefined) {
          this.showSnackbar({ message: this.$t('нет_суммы_для_оплаты_qr') })
          return
        }

        if (this.ticketRequest.payments.length > 1) {
          this.showSnackbar({ message: this.$t('смешанная_оплата_не_поддерживается_в_kaspi_qr') })
          return
        }

        this.m4bankType = 'KASPI_QR'
        this.m4bankAttributes = null
      }

      // If cashRegister is in TRIAL mode or pin checked then continues
      if (this.cashRegister.status === 'TRIAL' || this.cashRegisterPinChecked) {
        this.doAfterPinChecked()
      } else {
        this.cashRegisterPinpadDialog = true
      }
    },

    checkPin(pincode) {
      this.cashRegisterPinpadLoading = true
      const form = new FormData()
      form.append('password', pincode)
      this.checkPassword({
        cashRegister: this.cashRegister,
        data: form,
      }).then((result) => {
        if (result.data.result) {
          this.cashRegisterPinpadDialog = false
          this.setCashRegisterPinChecked(true)
          this.doAfterPinChecked()
        } else {
          this.cashRegisterPinpadErrorMessage = this.$t('введен_неверный_пин-код')
        }
        this.cashRegisterPinpadLoading = false
      }).catch(() => {
        this.cashRegisterPinpadLoading = false
      })
    },

    doAfterPinChecked() {
      switch (this.flow) {
        case 'FFB_POS':
          this.ffbPos()
          break
        case 'HALYK_POS':
          this.m4bankOperation(this.m4bankType)
          break
        case 'KASPI_QR':
          this.kaspiQRDialog = true
          break
        default:
          this.register()
          break
      }
    },

    // Register ticket
    register() {
      let total = 0

      if (this.selectedOperationType.includes('OPERATION')) {
        total = this.getNumberFromBillsAndCoins(this.ticketRequest.amounts.total)
      } else if (this.selectedOperationType.includes('MONEY')) {
        total = this.getNumberFromBillsAndCoins(this.ticketRequest.sum)
      }

      if (new Decimal(total).greaterThan(this.preferences.operationTotalLimit)) {
        this.showSnackbar({ message: this.$t('превышена_максимальная_сумма_чека_вы_можете_изменить_ее_в_меню_интерфейс', { maxSum: this.$options.filters.numeral(this.preferences.operationTotalLimit, '0,0') }), url: 'https://link.rekassa.kz/max-sum' })
        return
      }

      this.registerLoading = true
      this.requestSent = true

      // Register ticket
      if (this.selectedOperationType.includes('OPERATION')) {
        this.registerTicket({
          cashRegister: this.cashRegister,
          ticket: this.getTicketRequestJSON(this.ticketRequest),
          uuid: this.uuidString,
        }).then(async (result) => {
          if (this.cashRegister.status === 'REGISTERED') {
            this.analyticsLogEvent({
              eventName: 're_ticket_registered',
              eventProperties: {
                type: localStorage.getItem('rekassa.kz-ticket-prepared-from') || 'CALCULATOR',
              },
            })
            this.processItemsForAnalytics(result)
          }

          let ticket = result.data

          // Set M4bank attributes
          if (this.m4bankType) {
            const resultWithAttr = await this.setM4bankAttributes(result)
            ticket = resultWithAttr?.data || ticket

            this.analyticsLogEvent({
              eventName: 're_ticket_registered_payment_provider',
              eventProperties: { provider: this.m4bankType },
            })
          }

          this.setTicketRequest(null)
          this.setTicket(ticket)
          if (!this.$isCordova()) {
            this.showSnackbar({ message: this.$t('чек_№_успешно_фискализирован', { number: ticket.shiftDocumentNumber }), timeout: 1500 })
          }
          this.$router.push(`/print/${this.cashRegister.id}/${ticket.id}${this.$isFFBMode() || this.printerSettings.autoPrint ? '?autoprint=true' : ''}`)
          this.registerLoading = false
          this.checkIfOffline(ticket)
        }).catch((error) => {
          this.processError(error)
          this.registerLoading = false
        })
      }

      // Register cash
      if (this.selectedOperationType.includes('MONEY')) {
        this.registerCash({
          cashRegister: this.cashRegister,
          cash: this.getMoneyPlacementRequestJSON(this.ticketRequest),
          uuid: this.uuidString,
        }).then((result) => {
          this.setTicketRequest(null)
          this.setTicket(result.data)
          if (!this.$isCordova()) {
            this.showSnackbar({ message: this.$t('чек_№_успешно_фискализирован', { number: result.data.shiftDocumentNumber }), timeout: 1500 })
          }
          this.$router.push(`/print/${this.cashRegister.id}/${result.data.id}}`)
          this.registerLoading = false
          this.checkIfOffline(result.data)
        }).catch((error) => {
          this.processError(error)
          this.registerLoading = false
        })
      }
    },

    setM4bankAttributes(result) {
      return new Promise((resolve) => {
        this.setTicketBulkAttributes({
          cashRegister: this.cashRegister,
          ticket: result.data,
          cfg: {
            params: {
              paymentType: this.m4bankType,
              m4bankMerchantName: this.m4bankAttributes?.merchantName,
              m4bankDateTime: this.m4bankAttributes?.dateTime,
              m4bankOperationDay: this.m4bankAttributes?.operationDay,
              m4bankTransactionNumber: this.m4bankAttributes?.transactionNumber,
              m4bankTerminalId: this.m4bankAttributes?.terminalId,
              m4bankMaskedPan: this.m4bankAttributes?.maskedPan,
              m4bankAuthorizationCode: this.m4bankAttributes?.authorizationCode,
              m4bankRRN: this.m4bankAttributes?.rrn,
            },
          },
        }).then((resultWithAttr) => {
          resolve(resultWithAttr)
        }).catch((error) => {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          resolve(null)
        })
      })
    },

    checkIfOffline(ticket) {
      if (this.cashRegister.fdoMode === 'ONLINE' && ticket.offline) {
        this.showSnackbar({ message: this.$t('внимание_касса_перешла_в_автономный_режим') })
      }
    },

    checkIfShiftExpired() {
      if (this.cashRegister.shiftExpired) {
        this.showConfirm({
          title: this.$t('требуется_закрыть_смену'),
          resolveText: this.$t('закрыть_смену'),
          rejectText: this.$t('отменить'),
        }).then(() => {
          this.$router.push(`/shifts/${this.cashRegister.id}/${this.cashRegister.shiftNumber}/zxreport`)
        }).catch(() => {})
        return true
      }
      return false
    },

    getTicketRequestJSON(ticketRequest) {
      // Generating Discount, Markup items
      if (ticketRequest.items) {
        const discountMarkupItems = []
        ticketRequest.items.forEach(item => {
          if (item.type === fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_COMMODITY) {
            // Item commodity tax
            const tax = item.commodity.taxes && item.commodity.taxes[0]

            // Item discount
            const discount = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT')
            if (discount) {
              const discountType = discount.value.includes('%') ? 'PERCENTAGE' : 'VALUE'
              const discountPercentage = discount.value.replace('%', '')
              const discountValue = discountType === 'PERCENTAGE' ? new Decimal(discountPercentage).times(this.getNumberFromBillsAndCoins(item.commodity.sum)).dividedBy(100).toNumber()
                .toFixed(2) : discount.value
              const discountItem = new fdo.kkm.proto.TicketRequest.Item({
                type: fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_DISCOUNT,
                discount: new fdo.kkm.proto.TicketRequest.Modifier({
                  sum: this.getBillsAndCoinsMoney(discountValue),
                  name: item.commodity.name,
                }),
              })

              const discountMarkupTax = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT_MARKUP_TAX')
              if (tax && discountMarkupTax) {
                discountItem.taxes = [
                  new fdo.kkm.proto.TicketRequest.Tax({
                    taxType: 100,
                    taxationType: tax.taxation_type,
                    percent: tax.percent,
                    sum: this.getBillsAndCoinsMoney(new Decimal(discountMarkupTax.value).abs()),
                    isInTotalSum: true,
                  }),
                ]
              }

              discountMarkupItems.push(discountItem)
            }

            // Item markup
            const markup = item.commodity.auxiliary.find((aux) => aux.key === 'MARKUP')
            if (markup) {
              const markupType = markup.value.includes('%') ? 'PERCENTAGE' : 'VALUE'
              const markupPercentage = markup.value.replace('%', '')
              const markupValue = markupType === 'PERCENTAGE' ? new Decimal(markupPercentage).times(this.getNumberFromBillsAndCoins(item.commodity.sum)).dividedBy(100).toNumber()
                .toFixed(2) : markup.value
              const markupItem = new fdo.kkm.proto.TicketRequest.Item({
                type: fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_MARKUP,
                markup: new fdo.kkm.proto.TicketRequest.Modifier({
                  sum: this.getBillsAndCoinsMoney(markupValue),
                  name: item.commodity.name,
                }),
              })

              const discountMarkupTax = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT_MARKUP_TAX')
              if (tax && discountMarkupTax) {
                markupItem.taxes = [
                  new fdo.kkm.proto.TicketRequest.Tax({
                    taxType: 100,
                    taxationType: tax.taxation_type,
                    percent: tax.percent,
                    sum: this.getBillsAndCoinsMoney(new Decimal(discountMarkupTax.value).abs()),
                    isInTotalSum: true,
                  }),
                ]
              }

              discountMarkupItems.push(markupItem)
            }
          }
        })
        ticketRequest.items = ticketRequest.items.concat(discountMarkupItems)
      }

      // Be aware of case "DateTime"
      ticketRequest.dateTime = new fdo.kkm.proto.DateTime({
        date: new fdo.kkm.proto.Date({ year: this.$moment().format('YYYY'), month: this.$moment().format('M'), day: this.$moment().format('D') }),
        time: new fdo.kkm.proto.Time({ hour: this.$moment().format('HH'), minute: this.$moment().format('mm'), second: this.$moment().format('ss') }),
      })

      // Operator
      ticketRequest.operator = new fdo.kkm.proto.Operator({ code: this.user.id, name: this.user.name })

      return ticketRequest.toJSON()
    },

    getMoneyPlacementRequestJSON(moneyPlacementRequest) {
      // Be aware of case "Datetime"
      moneyPlacementRequest.datetime = new fdo.kkm.proto.DateTime({
        date: new fdo.kkm.proto.Date({ year: this.$moment().format('YYYY'), month: this.$moment().format('M'), day: this.$moment().format('D') }),
        time: new fdo.kkm.proto.Time({ hour: this.$moment().format('HH'), minute: this.$moment().format('mm'), second: this.$moment().format('ss') }),
      })

      return moneyPlacementRequest.toJSON()
    },

    processError(error) {
      if (error && error.response && error.response.data && error.response.data.code === 'CASH_REGISTER_SHIFT_PERIOD_EXPIRED') {
        this.showConfirm({
          title: this.$t('требуется_закрыть_смену'),
          text: error.response.data.meta && error.response.data.meta.ALLOWED_SHIFT_MINUTES ? this.$t('прошло_больше_часов_с_начала_открытия_смены_dotdotdot', { hours: error.response.data.meta.ALLOWED_SHIFT_MINUTES / 60 }) : null,
          resolveText: this.$t('закрыть_смену'),
          rejectText: this.$t('отменить'),
        }).then(() => {
          this.$router.push(`/shifts/${this.cashRegister.id}/${this.cashRegister.shiftNumber}/zxreport`)
        }).catch(() => {})
      }
    },

    // FFB POS
    ffbPos() {
      // OPERATION_SELL
      if (this.selectedOperationType === 'OPERATION_SELL') {
        this.registerLoading = true

        this.ffbPosPayment({ amount: this.totalSum }).then((paymentResult) => {
          this.m4bankType = 'FFB_POS'
          this.m4bankAttributes = paymentResult
          this.register()
        }).catch((error) => {
          this.m4bankErrorText = `${this.$t('ошибка_оплаты')}: ${error}`
          this.registerLoading = false
          this.m4bankErrorDialog = true
        })
      // OPERATION_SELL_RETURN
      } else if (this.selectedOperationType === 'OPERATION_SELL_RETURN') {
        this.ffbPosReversalAuthCode = null
        this.ffbPosReversalRrnCode = null
        this.ffbPosReversalRrnDialog = true
      }
    },

    ffbPosReversal() {
      if (this.$refs.ffbPosReversalForm.validate()) {
        this.ffbPosReversalRrnDialog = false
        this.registerLoading = true

        this.ffbPosRefund({
          amount: this.totalSum,
          authCode: this.ffbPosReversalAuthCode,
          rrnCode: this.ffbPosReversalRrnCode,
        }).then((paymentResult) => {
          this.m4bankType = 'FFB_POS'
          this.m4bankAttributes = paymentResult
          this.register()
        }).catch((error) => {
          this.m4bankErrorText = `${this.$t('ошибка_возврата')}: ${error}`
          this.registerLoading = false
          this.m4bankErrorDialog = true
        })
      }
    },

    skipFfbPosReversal() {
      this.ffbPosReversalRrnDialog = false
      this.register()
    },

    // HALYK POS
    m4bankOperation(type) {
      this.registerLoading = true
      this.m4bankRequestOperation({
        cashRegisterId: this.cashRegister.id,
        ticket: this.getTicketRequestJSON(this.ticketRequest),
        bankType: type,
      }).then((paymentResult) => {
        if (paymentResult && paymentResult.result && paymentResult.result.code === 0 && paymentResult.transaction) {
          this.m4bankType = type
          this.m4bankAttributes = paymentResult.transaction
          this.register()
        } else {
          this.m4bankErrorText = `${this.$t('ошибка_оплаты')}: ${paymentResult.result.errorData.description}`
          this.registerLoading = false
          this.m4bankErrorDialog = true
        }
      }).catch((error) => {
        this.m4bankErrorText = `${this.$t('ошибка_оплаты')}: ${error}`
        this.registerLoading = false
        this.m4bankErrorDialog = true
      })
    },

    kaspiQRPaid() {
      this.kaspiQRDialog = false
      this.register()
    },
  },
}
</script>

<style lang="stylus" scoped>
.fixed-bottom {
  position: fixed;
  bottom: 30px;
  width: 100%;
  max-width: 400px;
  padding: 0px 30px;
  left: 50%;
  transform: translateX(-50%);
}

.kaspi-qrcode-img
  position absolute
  margin-top 70px

.kaspi-qrcode
  display inline-block
  padding 8px 8px 1px 8px
  background #fffffe
  background-image linear-gradient(#fffffe, #fffffe)

.kaspi-corners
  display inline-table
  padding 10px
  background:
    linear-gradient(to right, #F14834 4px, transparent 4px) 0 0,
    linear-gradient(to right, #F14834 4px, transparent 4px) 0 100%,
    linear-gradient(to left, #F14834 4px, transparent 4px) 100% 0,
    linear-gradient(to left, #F14834 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, #F14834 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, #F14834 4px, transparent 4px) 100% 0,
    linear-gradient(to top, #F14834 4px, transparent 4px) 0 100%,
    linear-gradient(to top, #F14834 4px, transparent 4px) 100% 100%
  background-repeat no-repeat
  background-size 30px 30px
</style>
