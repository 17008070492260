<template lang="pug">
.mt-4.mt-md-6.pb-md-4
  .text-h6.text-decoration-none.font-weight-bold.mb-2.re-link.d-sm-none(@click="$router.push('/miniapp?name=analytics'); analyticsLogEvent({ eventName: 're_analytics_clicked_menu', eventProperties: { click_button: 'analytics' } })") {{ $t("аналитика") }}
    v-icon.ml-1.icon-large mdi-chevron-right
  div.mt-3
    v-card.rounded-xl
      v-container.py-1.ml-sm-1
        v-row
          v-col.pa-0(cols="12" sm="7")
            v-img.d-block.d-sm-none(src="/static/analytics-dashboard.png" eager)
            v-img.d-none.d-sm-block(src="/static/analytics-dashboard-sm.png" max-height="150px" position="bottom center" eager)
          v-col(cols="12" sm="5")
            v-row.pt-0
              v-col.pt-1.px-10(cols="12")
                p.text-h6.d-none.d-sm-block.mb-2 {{ $t("аналитика") }}
                p.text-center.text-sm-left.text-subtitle-2 {{ $t("держите_руку_на_пульсе_ключевые_метрики_вашего_бизнеса_в_одном_месте") }}
              v-col.py-1.px-8(cols="12")
                v-btn(:block="!this.$vuetify.breakpoint.smAndUp" small color="primary" @click="$router.push('/miniapp?name=analytics'); analyticsLogEvent({ eventName: 're_analytics_clicked_menu', eventProperties: { click_button: 'view' } })") {{ $t("смотреть") }}
                  v-icon.ml-1(small) mdi-arrow-right
</template>
<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      analyticsLogEvent: 'analytics/logEvent',
    }),
  },
}
</script>
<style scoped lang="stylus">
.icon-large
  font-size 24px
  @media (min-width: 960px)
    font-size 32px
</style>
