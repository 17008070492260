<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/uplus/cart')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("способы_оплаты") }}

  v-main
    v-container.d-flex.wrapper.flex-column.flex-md-row.justify-space-between.full-height.pt-6.pt-md-14.pb-15
      div.col-md-6.pa-0.flex-item
        v-container.pa-0
          re-products-list(:products="cartList" readonly)
      div.col-md-6.pa-0.flex-item
        re-discount-list(:total="totalPriceWithoutDiscount" @discount="setDiscount").my-5.mx-2.ma-md-0
        v-container.mt-5.pa-md-0
          v-row.ma-0.justify-space-between.mt-1.mb-md-8.mb-6
            p.text-h4.text-md-h1.text-uppercase {{ $t("итого") }}
            p.text-h4.text-md-h1 {{ totalPrice | numeral('0,0.[00]') }} ₸
          v-col.pa-0
            v-col.mx-0.mb-4.gap.mt-0.pa-0
              v-btn(height="auto" elevation="0" :color="paymentType === 'PAYMENT_CASH' ? 'primary' : 'anchorBtn'" width="100%" @click="paymentType = 'PAYMENT_CASH'").flex-grow-1.flex-shrink-0.py-3.pl-3.pr-4.px-md-4.py-md-3.mb-1
                v-row.align-center.justify-space-between.col-12.ma-0.pa-0
                  v-row.align-center.ma-0.pa-0
                    v-icon(width="32" height="32").mr-2.flex-grow-0 mdi-cash-multiple
                    div
                      p.font-weight-bold.text-subtitle-1.text-md-h4.text-left {{ $t('paymentType.PAYMENT_CASH') }}
                      p.text-md-subtitle-2.text-overline.text-left {{ $t("наличные_деньги") }}
                  p.text-h4 {{ paymentType === 'PAYMENT_CASH' ? totalPrice : 0 }}
              v-btn(height="auto" elevation="0" :color="paymentType === 'PAYMENT_CARD' ? 'primary' : 'anchorBtn'" width="100%" @click="paymentType = 'PAYMENT_CARD'").flex-grow-1.flex-shrink-0.py-3.pl-3.pr-4.px-md-4.py-md-3.mb-1
                v-row.align-center.justify-space-between.col-12.ma-0.pa-0
                  v-row.align-center.ma-0.pa-0
                    v-icon(width="32" height="32").mr-2.flex-grow-0 mdi-credit-card
                    div
                      p.text-md-h4.font-weight-bold.text-subtitle-1.text-md-h4.text-left {{ $t('paymentType.PAYMENT_CARD') }}
                      p.text-md-subtitle-2.text-overline.text-left {{ $t("оплата_через_pos_терминал") }}
                  p.text-h4 {{ paymentType === 'PAYMENT_CARD' ? totalPrice : 0 }}
              v-btn(height="auto" elevation="0" :color="paymentType === 'PAYMENT_MOBILE' ? 'primary' : 'anchorBtn'" width="100%" @click="paymentType = 'PAYMENT_MOBILE'").flex-grow-1.flex-shrink-0.py-3.pl-3.pr-4.px-md-4.py-md-3
                v-row.align-center.justify-space-between.col-12.ma-0.pa-0
                  v-row.align-center.ma-0.pa-0
                    v-icon(:width="32" height="32").mr-2.flex-grow-0 mdi-cellphone-dock
                    div
                      p.text-md-h4.font-weight-bold.text-subtitle-1.text-md-h4.text-left {{ $t('paymentType.PAYMENT_MOBILE') }}
                      p.text-md-subtitle-2.text-overline.text-left {{ $t("удалённая_оплата_qr_платежи") }}
                  p.text-h4 {{ paymentType === 'PAYMENT_MOBILE' ? totalPrice : 0 }}
          v-btn(elevaion="0" color="secondary" @click="next").col-12.text-h5.font-weight-bold.primaryDarken1--text {{ $t("далее") }}

</template>

<script>
import { Decimal } from 'decimal.js'
import fdo from '@comrun/kfdo'
import { mapState, mapActions } from 'vuex'
import store from '../../store/index'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'
import ProductsList from './parts/ProductsList.vue'
import Discount from './parts/Discount.vue'

export default {
  components: {
    're-products-list': ProductsList,
    're-discount-list': Discount,
  },

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    searchText: null,
    selectedCategory: null,
    paymentType: 'PAYMENT_CASH',
    discount: 0,
    discountType: 'PERCENTAGE',
  }),

  computed: {
    ...mapState({
      cartList: state => state.positions.cartList,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),

    // calculate total price of all items in cartList
    totalPrice() {
      return this.cartList.reduce((acc, item) => acc + item.totalPrice, 0) - this.discountAmount
    },

    discountAmount() {
      if (this.discountType === 'PERCENTAGE') {
        return new Decimal(this.totalPriceWithoutDiscount).times(this.discount).dividedBy(100).toNumber()
          .toFixed(2)
      }
      return this.discount
    },

    totalPriceWithoutDiscount() {
      return this.cartList.reduce((acc, item) => acc + item.totalPrice, 0)
    },
  },

  watch: {

  },

  created() {
    this.init()
    this.setTicketRequest(null)
  },

  methods: {
    ...mapActions({
      setTicketRequest: 'cashRegisters/setTicketRequest',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    init() {

    },

    setDiscount(discount) {
      this.discount = discount.value
      this.discountType = discount.type
    },

    prepareTicketRequest() {
      // prepare ticket request for KKM
      const ticketRequest = new fdo.kkm.proto.TicketRequest()

      ticketRequest.operation = fdo.kkm.proto.OperationTypeEnum.OPERATION_SELL
      ticketRequest.domain = new fdo.kkm.proto.TicketRequest.Domain({ type: this.dictionary.domainType[this.preferences.domainType].code })

      this.cartList.forEach((item) => {
        ticketRequest.items.push(this.prepareItem(item))
      })

      const payment = new fdo.kkm.proto.TicketRequest.Payment({
        type: fdo.kkm.proto.PaymentTypeEnum[this.paymentType],
        sum: this.getBillsAndCoinsMoney(this.totalPrice),
      })
      ticketRequest.payments.push(payment)

      const amounts = new fdo.kkm.proto.TicketRequest.Amounts({
        total: this.getBillsAndCoinsMoney(this.totalPrice),
        taken: this.getBillsAndCoinsMoney(this.paymentType === 'PAYMENT_CASH' ? this.totalPrice : 0),
        change: this.getBillsAndCoinsMoney(0),
      })

      if (this.discount > 0) {
        const discountMarkup = new fdo.kkm.proto.TicketRequest.Modifier({
          sum: this.getBillsAndCoinsMoney(this.discountAmount),
          auxiliary: [
            new fdo.kkm.proto.KeyValuePair({
              key: 'DISCOUNT',
              value: this.discountType === 'PERCENTAGE' ? `${this.discount}%` : `${this.discount}`,
            }),
          ],
        })
        amounts.discount = discountMarkup
      }

      ticketRequest.amounts = amounts

      this.setTicketRequest(ticketRequest)
    },

    prepareItem(item) {
      const sum = new Decimal(item.price).times(item.quantity).toNumber().toFixed(2)

      console.log(item.unitType)

      // prepare item for ticket request
      const ticketRequestItem = new fdo.kkm.proto.TicketRequest.Item({
        type: fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_COMMODITY,
        commodity: new fdo.kkm.proto.TicketRequest.Item.Commodity({
          name: item.name,
          sectionCode: '1',
          quantity: new Decimal(item.quantity).times(1000).toNumber(),
          price: this.getBillsAndCoinsMoney(item.price),
          sum: this.getBillsAndCoinsMoney(sum),
          measureUnitCode: item.measureUnitCode,
          auxiliary: [],
        }),
      })

      const unitType = new fdo.kkm.proto.KeyValuePair({
        key: 'UNIT_TYPE',
        value: item.unitType,
      })
      ticketRequestItem.commodity.auxiliary.push(unitType)

      const sku = new fdo.kkm.proto.KeyValuePair({
        key: 'INVENTORY_ID',
        value: item.id,
      })
      ticketRequestItem.commodity.auxiliary.push(sku)

      if (this.configuration.taxMode && this.dictionary.taxType[this.preferences.defaultTaxType].rate >= 0) {
        ticketRequestItem.commodity.taxes = [
          new fdo.kkm.proto.TicketRequest.Tax({
            taxType: 100,
            taxationType: this.dictionary.taxationType[this.configuration.taxationType].code,
            percent: new Decimal(this.dictionary.taxType[this.preferences.defaultTaxType].rate).times(1000).toNumber(),
            sum: this.getBillsAndCoinsMoney(new Decimal(sum)
              .dividedBy(new Decimal(this.dictionary.taxType[this.preferences.defaultTaxType].rate).add(100))
              .times(this.dictionary.taxType[this.preferences.defaultTaxType].rate)
              .toNumber()
              .toFixed(2)),
            isInTotalSum: true,
          }),
        ]
        ticketRequestItem.commodity.taxes[0].sum.value = this.commodityTax
      }

      return ticketRequestItem
    },

    next() {
      // try {

      if (this.totalPrice < 0) {
        this.showSnackbar({ message: this.$t('сумма_должна_быть_больше_нуля'), timeout: 2000 })
        return
      }

      this.prepareTicketRequest()
      localStorage.setItem('rekassa.kz-ticket-prepared-from', 'INVENTORY')
      this.$router.push('/draft')
      // } catch (error) {
      //   console.debug(`error: ${error}`)
      // }

      this.analyticsLogEvent({ eventName: 're_inventory_selected_payment_method', eventProperties: { payment_type: this.paymentType } })
      this.analyticsLogEvent({ eventName: 're_inventory_ticket_preview_page' })
    },
  },

  beforeRouteEnter(to, from, next) {
    if (store.state.positions.cartList.length > 0) {
      next()
    } else {
      next('/uplus/cart')
    }
  },
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

.border-radius
  border-radius 4px

.wrapper
  @media screen and (min-width: 960px)
    padding 0 24px
    max-width 1200px
    margin 0 auto
    gap 48px

.flex-item
  flex 1 0 0
</style>
