import Vue from 'vue'
import axios from 'axios'
import { getLocale } from '../i18n'
import store from '../store/index'

const api = {
  init() {
    window.FirebasePlugin.onMessageReceived((message) => {
      console.debug('FCM(device): onMessageReceived')
      console.debug(message)
      if (message && message.tap) {
        if (message.url) {
          Vue.prototype.$openLink(message.url, '_system')
        }
      }
      if (window.device.platform === 'iOS') {
        window.FirebasePlugin.setBadgeNumber(0)
      }
    }, (error) => {
      console.error(error)
    })

    window.FirebasePlugin.hasPermission((hasPermission) => {
      if (hasPermission) {
        window.FirebasePlugin.onTokenRefresh((token) => {
          console.debug('FCM(device): onTokenRefresh: ', token)
          this.subscribe(token)
        }, (error) => {
          console.error(error)
        })
      } else {
        window.FirebasePlugin.grantPermission((granted) => {
          console.debug(`FCM(device): Notifications permission was ${granted ? 'granted' : 'denied'}`)
          if (granted) {
            window.FirebasePlugin.onTokenRefresh((token) => {
              console.debug('FCM(device): onTokenRefresh: ', token)
              this.subscribe(token)
            }, (error) => {
              console.error(error)
            })
          }
        })
      }
    })

    // Create channel for Android for custom sound
    if (window.device.platform === 'Android') {
      window.FirebasePlugin.createChannel({
        id: 'shift_closed',
        sound: 'shift_closed',
        name: 'shift_closed',
      },
      () => {
        console.debug('FCM(device): Channel created: shift_closed')
      },
      (error) => {
        console.debug(`FCM(device): Create channel error: ${error}`)
      })
    }
  },

  subscribe(token) {
    (async () => {
      const { uuid } = window.device

      const currentUser = await new Promise(resolve => { window.FirebasePlugin.getCurrentUser((user) => resolve(user)) })
      const bearerToken = await currentUser.idToken
      const config = {
        headers: { Authorization: `Bearer ${bearerToken}`, 'x-user-uid': currentUser.uid },
      }
      let url = '/notification/users/tokens'
      if (process.env.VUE_APP_API_ROOT_URL) {
        url = process.env.VUE_APP_API_ROOT_URL + url
      }
      axios.post(
        url, {
          deviceId: uuid, token, lang: getLocale().toUpperCase(), detailedInfo: this.getDetailedInfo(), deviceType: 'MOBILE',
        },
        config,
      ).then(() => {
        console.debug(`FCM(device): Subscribed for push notifications deviceId: ${uuid}`)
      }).catch((error) => {
        console.debug('FCM(device): Failed to subscribe: ', error)
      })
    })()
  },

  unsubscribe() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      try {
        const { uuid } = window.device

        const currentUser = await new Promise(res => { window.FirebasePlugin.getCurrentUser((user) => res(user)) })
        const bearerToken = await currentUser.idToken
        const config = {
          headers: { Authorization: `Bearer ${bearerToken}`, 'x-user-uid': currentUser.uid },
        }
        let url = `/notification/users/device-ids/${uuid}`
        if (process.env.VUE_APP_API_ROOT_URL) {
          url = process.env.VUE_APP_API_ROOT_URL + url
        }
        axios.delete(
          url,
          config,
        ).then(() => {
          console.debug(`FCM(device): Unsubscribed for push notifications deviceId: ${uuid}`)
          resolve()
        }).catch((error) => {
          console.debug('FCM(device): Failed to unsubscribed: ', error)
          resolve()
        })
      } catch (e) {
        resolve()
      }
    })
  },

  getDetailedInfo() {
    const info = {}
    try {
      info.cordovaBuildVersion = store.state.tools.cordovaBuildVersion
      info.device = {
        model: window.device.model,
        platform: window.device.platform,
        version: window.device.version,
        manufacturer: window.device.manufacturer,
      }
    } catch (error) {
      console.debug(error)
    }
    return info
  },
}

export default api
