/* eslint-disable prefer-destructuring */
import { setUserId, setUserProperties, logEvent } from 'firebase/analytics'
import md5 from 'md5'
import moment from 'moment'

const analytics = {
  namespaced: true,

  state: {
    firebaseAnalytics: null,
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    setFirebaseAnalytics({ commit }, payload) {
      commit('set', { type: 'firebaseAnalytics', value: payload })
    },

    setUserId({ state }, payload) {
      if (window.cordova) {
        window.FirebasePlugin.setUserId(payload)
        window.plugins.appsFlyer.setAppUserId(payload)
      } else {
        setUserId(state.firebaseAnalytics, payload)
      }
    },

    setUserProperty({ state }, payload) {
      if (window.cordova) {
        window.FirebasePlugin.setUserProperty(payload.key, payload.value)
      } else {
        const jsonVariable = {}
        jsonVariable[payload.key] = payload.value
        setUserProperties(state.firebaseAnalytics, jsonVariable)
      }
    },

    logEvent({ state, rootState }, payload) {
      const eventProperties = payload.eventProperties || {}

      // Prevent sending phone number and user_id to analytics from miniapps
      eventProperties.phone_number = undefined
      eventProperties.user_id = undefined

      // Pass user uid, cash register id and organization id to analytics by default
      // Hash serial number, business id for privacy
      if (rootState.auth?.user?.uid) {
        eventProperties.user_id = rootState.auth.user.uid
      }
      if (rootState.cashRegisters?.cashRegister?.cashRegister?.id) {
        eventProperties.cash_register_id = rootState.cashRegisters.cashRegister.cashRegister.id
        eventProperties.cash_register_serial_number_hash = md5(rootState.cashRegisters.cashRegister.cashRegister.serialNumber)
      }
      if (rootState.cashRegisters?.cashRegister?.organization?.id) {
        eventProperties.organization_id = rootState.cashRegisters.cashRegister.organization.id
        eventProperties.organization_business_id_hash = md5(rootState.cashRegisters.cashRegister.organization.businessId)
      }

      // Pass UTM parameters to analytics from localStorage
      const utmSource = localStorage.getItem('rekassa.kz-marketing-utm_source') || ''
      const utmMedium = localStorage.getItem('rekassa.kz-marketing-utm_medium') || ''
      const utmCampaign = localStorage.getItem('rekassa.kz-marketing-utm_campaign') || ''
      const utmTerm = localStorage.getItem('rekassa.kz-marketing-utm_term') || ''
      const utmContent = localStorage.getItem('rekassa.kz-marketing-utm_content') || ''
      const pageTitle = localStorage.getItem('rekassa.kz-marketing-page_title') || ''
      const pagePath = localStorage.getItem('rekassa.kz-marketing-page_path') || ''
      const referrer = localStorage.getItem('rekassa.kz-marketing-referrer') || ''

      eventProperties.utm_source = utmSource
      eventProperties.utm_medium = utmMedium
      eventProperties.utm_campaign = utmCampaign
      eventProperties.utm_term = utmTerm
      eventProperties.utm_content = utmContent
      eventProperties.page_title = pageTitle
      eventProperties.page_path = pagePath
      eventProperties.referrer = referrer

      // Pass additional parameters to analytics
      if (rootState.auth.user && rootState.cashRegisters?.cashRegister?.cashRegister) {
        const { cashRegister, roles } = rootState.cashRegisters.cashRegister
        const { configuration } = cashRegister.data
        const { preferences } = cashRegister.data
        eventProperties.re_tax_mode = configuration.taxMode
        eventProperties.re_auto_cash_withdrawal = configuration.withdrawMoney
        eventProperties.re_z_report_email = configuration.closeShiftEmail ? 'true' : 'false'
        eventProperties.re_fdo_type = cashRegister.fdo
        eventProperties.re_cash_register_activation_date = cashRegister.registrationDate ? moment(cashRegister.registrationDate).format('DD-MM-YYYY') : ''
        eventProperties.re_domain_type = preferences.domainType

        let cashRegisterStatus
        const { status, fdoMode } = cashRegister
        if (status === 'REGISTERED') {
          cashRegisterStatus = fdoMode
        } else {
          cashRegisterStatus = status
        }
        eventProperties.re_cash_register_status = cashRegisterStatus

        eventProperties.re_user_role = roles[0]

        const { user } = rootState.auth
        eventProperties.re_user_registration_date = moment(user.registrationDate).format('DD-MM-YYYY')
      }

      if (process.env.VUE_APP_TEST === 'true') {
        console.debug(`logEvent: ${payload.eventName} ${eventProperties ? 'eventProperties: ' : ''}`, eventProperties)
      }

      if (window.cordova) {
        window.FirebasePlugin.logEvent(payload.eventName, eventProperties)
        window.plugins.appsFlyer.logEvent(payload.eventName, eventProperties, null, (error) => {
          try {
            window.FirebasePlugin.logEvent('re_apps_flyer_logevent_error', { eventError: error, eventName: payload.eventName, eventProperties: payload.eventProperties })
          } catch (err) {
            // console.debug(err)
          }
        })
      } else {
        logEvent(state.firebaseAnalytics, payload.eventName, eventProperties)
        window.fbq('trackCustom', payload.eventName, eventProperties)
      }
    },

    setScreenName({ state }, payload) {
      if (window.cordova) {
        window.FirebasePlugin.setScreenName(payload)
        window.plugins.appsFlyer.logEvent('screen_view', { name: payload })
      } else if (state.firebaseAnalytics) {
        logEvent(state.firebaseAnalytics, 'screen_view', { firebase_screen: payload })
      }
    },

  },
}

export default analytics
