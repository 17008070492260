<template lang="pug">
  .d-flex.flex-grow-1.flex-md-grow-0.align-center.pl-lg-4.re-cursor-pointer.re-switch-hover(v-else text @click="determineAction()")
    template(v-if="status.code")
      .d-flex.flex-grow-1.flex-md-grow-0.justify-space-between.align-center.re-register-header(:class="`re-register-header-status-${status.code}`")
        .d-flex.flex-column
          .d-flex.mr-2
            v-icon.mr-2.white--text {{ status.icon }}
            .text-h5.text-md-subtitle-1.font-weight-bold.white--text {{ status.title }}
          .d-flex
            .text-subtitle-1.text-md-subtitle-2 {{ status.subtitle }}
        .d-flex(v-if="!$vuetify.breakpoint.mdAndUp")
          v-icon.ml-4.white--text {{ status.actionIcon }}
    template(v-else)
      .font-weight-bold.text-subtitle-1.text-lg-h5.pl-md-2 {{ cashRegister.name ? cashRegister.name : organization.businessName }}
      v-icon.ml-1 mdi-chevron-right
  </template>
<script>
import { mapState, mapActions } from 'vuex'
import i18n from '../../i18n/index'

export default {

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,

      status: state => {
        // Если текущая касса DEMO
        if (state.cashRegisters.cashRegister.cashRegister.status === 'TRIAL') {
          // Фильтруем список касс по статусу и наличию статуса регистрации
          const newList = state.cashRegisters.cashRegisterItemList.filter(item => item.cashRegister.status === 'NEW' && item.cashRegister.data.preferences?.ofd?.reg?.status?.code)

          // Если есть только одна касса с новым статусом
          if (newList.length === 1) {
            const { code } = newList[0].cashRegister.data.preferences.ofd.reg.status

            if (code === 'IN_PROGRESS') {
              return {
                code: 'NORMAL',
                icon: 'mdi-alert-circle-outline',
                title: i18n.t('продолжить_регистрацию_кассы'),
                actionIcon: 'mdi-chevron-right',
                action: 'miniapp',
                cashRegisterId: newList[0].cashRegister.id,
              }
            }

            if (code === 'SENT') {
              return {
                code: 'NORMAL',
                icon: 'mdi-progress-clock',
                title: i18n.t('регистрация_кассы'),
                subtitle: i18n.t('ожидаем_ответа_налоговой'),
                actionIcon: 'mdi-chevron-right',
                action: 'miniapp',
                cashRegisterId: newList[0].cashRegister.id,
              }
            }

            if (code === 'ERROR') {
              return {
                code: 'ERROR',
                icon: 'mdi-alert-circle-outline',
                title: i18n.t('ошибка_регистрации_кассы'),
                subtitle: i18n.t('узнать_подробнее'),
                actionIcon: 'mdi-chevron-right',
                action: 'miniapp',
                cashRegisterId: newList[0].cashRegister.id,
              }
            }
          }

          // Если есть несколько касс с новым статусом
          if (newList.length > 0) {
            return {
              code: 'NORMAL',
              icon: 'mdi-information-outline',
              title: i18n.t('новый_статус_регистрации'),
              subtitle: i18n.t('узнать_подробнее'),
              actionIcon: 'mdi-chevron-right',
              action: 'switcher',
            }
          }

          // Если нет касс с новым статусом
          return {
            code: 'NORMAL',
            icon: 'mdi-information-outline',
            title: i18n.t('зарегистрируйте_кассу'),
            actionIcon: 'mdi-chevron-right',
            action: 'miniapp',
          }

          // Проверка на успешный статус регистрации
        } if (state.cashRegisters.cashRegister.cashRegister.data.preferences?.ofd?.reg?.status?.code === 'SUCCESS') {
          return {
            code: 'SUCCESS',
            icon: 'mdi-check-circle-outline',
            title: i18n.t('поздравляем'),
            subtitle: i18n.t('ваша_касса_зарегистрирована'),
            actionIcon: 'mdi-close',
            action: 'miniapp',
            cashRegisterId: state.cashRegisters.cashRegister.cashRegister.id,
          }
        }

        // Иначе показываем переключатель касс
        return {
          action: 'switcher',
        }
      },

    }),
  },

  methods: {
    ...mapActions({
      showMiniApp: 'miniapp/show',
      showCashRegisterSwitch: 'tools/showCashRegisterSwitch',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    determineAction() {
      if (this.status.action === 'miniapp') {
        this.openMiniApp()
      } else {
        this.cashRegisterSwitch()
      }
    },

    openMiniApp() {
      const params = [
        { mode: 'reg' },
      ]
      if (this.status.cashRegisterId) {
        params.push({ switchToCashRegisterId: this.status.cashRegisterId })
      }

      this.showMiniApp({
        url: process.env.VUE_APP_MINIAPP_OFD_URL,
        name: process.env.VUE_APP_MINIAPP_OFD_NAME,
        params,
      })

      this.analyticsLogEvent({ eventName: 're_cash_activate_block_click_button' })
    },

    cashRegisterSwitch() {
      this.showCashRegisterSwitch(true)
      this.analyticsLogEvent({ eventName: 're_nav_cash_register_switch_clicked' })
    },
  },
}
</script>
<style lang="stylus" scoped>
.re-register-header
  @media (min-width: 960px)
    color #fff
    border-radius 12px
    padding 8px 20px
    min-height 50px

.re-register-header-status-NORMAL
  @media (min-width: 960px)
    background-color #5E9CF4 !important
    background-image url(/static/wave.png)
    background-size cover

.re-register-header-status-SUCCESS
  @media (min-width: 960px)
    background-color #18AE3E !important
    background-image url(/static/wave.png)
    background-size cover

.re-register-header-status-ERROR
  @media (min-width: 960px)
    background-color #FF1957 !important
    background-image url(/static/wave.png)
    background-size cover

@media (min-width: 960px)
  .re-switch-hover
    &:hover
      opacity 0.4
</style>
